import React, { useEffect, useState } from "react";
import { Container, Grid } from '@mui/material';

import UserTable from "./userTable";
import ButtonsOptions from "./buttonsOptions";

import { api } from '../../services/api';

const User = props => {
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
      let mounted = true;
      api.getUsers()
        .then(resp => {
            if (mounted && resp) {
                setUsersList(resp);
            }
        })
      return () => mounted = false;
  }, [])

  return (
      <Container maxWidth="xg" sx={{ mt: 4, mb: 4 }}>
          <Grid item xs={12}>
          <ButtonsOptions />
          </Grid>
          <Grid item xs={12}>
              <UserTable rows={usersList}/>
          </Grid>
      </Container>
  );

};

export default User;
