import React, { useState } from 'react';

import MuiAlert from '@mui/material/Alert';
import PageCreate from '../../components/pageCreate';
import FormGeneral from '../../components/forms/formGeneral';
import FormNationalAddress from '../../components/forms/formNationalAddress';
import FormContacts from '../../components/forms/formContacts';
import FormEmails from '../../components/forms/formEmails';
import { api } from '../../services/api';


const createNewExporter = async item => {
    const resp = api.postExporter(JSON.stringify(item));
    return resp;
}

export default function ExporterCreate() {
    const base = {
        "tipo": "Jurídica",
        "razaoSocial": "",
        "cnpj": "",
        "ativoDesde": "",
        "contatos": [
            {
                "nome": "",
                "email": "",
                "celular": "",
                "telefone": ""
            }
        ],
        "endereco": {
            "logradouro": "",
            "numero": "",
            "complemento": "",
            "bairro": "",
            "cidade": "",
            "estado": "",
            "pais": "",
            "zipCode": "",
            "caixaPostal": "null"
        },
        "emailsContabeis": [""],
        "emailsFinanceiros": [""]
    }

    const [inputFields, setInputFields] = useState(base);
    const [running, setRunning] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const handleSubmit = async e => {
        setRunning(true);
        e.preventDefault();
        const resp = await createNewExporter(inputFields);
        if (resp) {
            setMessage("Empresa cadastrada com sucesso!");
            setSeverity("success");
            setInputFields(base);
        } else {
            setMessage("Erro ao cadastrar empresa!");
            setSeverity("error");
        }
        setOpen(true);
    };

    const setEmailsFin = (target, idx) => {
        let emailsList = inputFields.emailsFinanceiros;
        emailsList[idx] = target;
        setInputFields((inputFields) => ({
            ...inputFields, emailsFinanceiros: emailsList
        }));
    }

    const setEmailsCont = (target, idx) => {
        let emailsList = inputFields.emailsContabeis;
        emailsList[idx] = target;
        setInputFields((inputFields) => ({
            ...inputFields, emailsContabeis: emailsList
        }));
    }

    return (
        <PageCreate title="Nova Exportadora" handleSubmit={handleSubmit} message={message} severity={severity} running={running} setRunning={setRunning} open={open} setOpen={setOpen}>
            <FormGeneral inputFields={inputFields} setInputFields={setInputFields} running={running} />
            <FormNationalAddress inputFields={inputFields} setInputFields={setInputFields} running={running} />
            <FormContacts inputFields={inputFields} setInputFields={setInputFields} running={running} />
            <FormEmails id="emailsFinanceiros" setEmails={setEmailsFin} inputFields={inputFields} setInputFields={setInputFields} running={running} emailField="emailsFinanceiros" title="Emails do financeiro" />
            <FormEmails id="emailsContabeis" setEmails={setEmailsCont} inputFields={inputFields} setInputFields={setInputFields} running={running} emailField="emailsContabeis" title="Emails da contabilidade" />
        </PageCreate>
    );
}