import axios from 'axios';
import { getToken } from '../services/auth';

let headers = {};
headers = axios.defaults.headers.common;

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export const axiosClient = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: headers,
    timeout: 30000
});

axiosClient.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`
        };
    }
    return config;
});
