import React, { useState, useEffect, useMemo } from "react";
import {
    Autocomplete,
    Grid,
    TextField,
    InputAdornment
} from "@mui/material";

import { MoneyTextMask } from '../../components/masks';
import FormCard from "./formCard";
import { sumReceivedValues, realToFloat, sumPreReceivedValues } from "../../common/utils";

const FormPreReceipt = (props) => {
    const fontSize = "12px";
    const { inputFields, setInputFields, srcSaleList, running } = props;
    const [valuesListSale, setValuesListSale] = useState([]);
    const [totalAvailable, setTotalAvailable] = useState(0);

    const handleSaleSelected = (value) => {
        if (value) {
            let valuesFiltered = [];
            valuesFiltered = srcSaleList.filter(sale => sale["code"] === value);
            setInputFields({ ...inputFields, vendaId: valuesFiltered[0]["id"] });
            const totalRecebido = sumReceivedValues(valuesFiltered[0]["recebimentos"]);
            setTotalAvailable(valuesFiltered[0]["totalDolar"] - (totalRecebido + sumPreReceivedValues(valuesFiltered[0]["preRecebimento"])));
        } else {
            setInputFields({ ...inputFields, vendaId: "" });
            setTotalAvailable(0);
        }
    }

    useEffect(() => {
        if (srcSaleList) {
            let valuesFiltered = [];
            valuesFiltered = srcSaleList.map(sale => sale["code"]);
            setValuesListSale(valuesFiltered)
        }
    }, [srcSaleList]);

    const isError = useMemo(() => {
        if (inputFields) {
            if (totalAvailable > 0 && realToFloat(inputFields.valor) > totalAvailable) {
                setInputFields({ ...inputFields, isBlocked: true });
                return true;
            }
            setInputFields({ ...inputFields, isBlocked: false });
            return false;
        }
        return false;
    }, [inputFields.valor, totalAvailable]);

    return (
        <Grid item xs={12} sm={12}>
            <FormCard title="Informações Gerais">
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            size="small"
                            disablePortal
                            id="venda"
                            name="venda"
                            options={valuesListSale}
                            onChange={(event, value) => handleSaleSelected(value)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField
                                required
                                helperText={totalAvailable !== 0 && `Total disponível: $ ${totalAvailable}`}
                                {...params}
                                InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                label="Código da Venda" />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={isError}
                            size='small'
                            required
                            disabled={running}
                            id="valor"
                            name="valor"
                            label="Valor do Pré-Recebimento (USD)"
                            fullWidth
                            value={inputFields.valor}
                            helperText={isError && `Valor do recebimento acima do disponível`}
                            onChange={event => setInputFields({ ...inputFields, valor: event.target.value })}
                            InputLabelProps={{ style: { fontSize: fontSize } }}
                            InputProps={{
                                style: { fontSize: fontSize },
                                inputComponent: MoneyTextMask,
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                        />
                    </Grid>
                </Grid>
            </FormCard>
        </Grid>
    );
}

export default FormPreReceipt;
