const endpoints = {
    // baseAddr: "http://localhost:8080/",
    baseAddr: "https://www.export.kaffee.com.br:8080/",
    login: "user/login",
    user: "user",
    product: "product",
    importer: "importer",
    exporter: "exporter",
    fininstitution: "fininstitution",
    receiving: "recebimento",
    sale: "venda",
    acc: "acc"
}

export default endpoints;