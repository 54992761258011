import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { isAdmin, isBasic, isManager } from "../services/auth";

import Acc from "../pages/acc/accList";
import AccCreate from "../pages/acc/create";
import AccView from "../pages/acc/viewEdit";
import Dash from "../pages/dashboard/dashboard";
import Dashboard from "../common/template/dashboard";
import Exporter from "../pages/exporter/exporterList";
import ExporterCreate from "../pages/exporter/create";
import ExporterView from "../pages/exporter/viewEdit";
import FinInstitution from "../pages/finInstitution/finInstitutionList";
import FinInstitutionCreate from "../pages/finInstitution/create";
import FinInstitutionView from "../pages/finInstitution/viewEdit";
import Importer from "../pages/importer/importerList";
import ImporterCreate from "../pages/importer/create";
import ImporterView from "../pages/importer/viewEdit";
import Product from "../pages/product/productList";
import ProductCreate from "../pages/product/create";
import ProductView from "../pages/product/viewEdit";
import ProtectedRoute from '../util/protectedRoute';
import React from "react";
import Receiving from "../pages/receiving/recevingList";
import PrereceiptList from "../pages/prereceiving/list";
import PrereceiptCreate from "../pages/prereceiving/create";
import ReceivingCreate from "../pages/receiving/create";
import ReceivingView from "../pages/receiving/viewEdit";
import SaleView from "../pages/sales/viewEdit";
import Sales from "../pages/sales/sales";
import SalesCreate from "../pages/sales/create";
import SignIn from "../pages/login/login";
import User from "../pages/users/users";
import UserView from "../pages/users/viewEdit";
import UsersCreate from "../pages/users/create";
import PreReceiptUpdate from "../pages/prereceiving/update";
import PreReceiptConvert from "../pages/prereceiving/convert";

const Routess = props => (
    <Router>
        <Routes>
            <Route exact path="/auth/login" element={
                <SignIn />
            } />
            <Route exact path="/users" element={
                <ProtectedRoute>
                    <Dashboard>
                        <User />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/accs" element={
                <ProtectedRoute>
                    <Dashboard>
                        <Acc />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/accs/create" element={
                <ProtectedRoute>
                    <Dashboard>
                        <AccCreate />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/accs/:id" element={
                <ProtectedRoute>
                    <Dashboard>
                        <AccView />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/receiving" element={
                <ProtectedRoute>
                    <Dashboard>
                        <Receiving />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/prereceiving" element={
                <ProtectedRoute>
                    <Dashboard>
                        <PrereceiptList />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/prereceiving/update/:id" element={
                <ProtectedRoute>
                    <Dashboard>
                        <PreReceiptUpdate />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/prereceiving/convert/:id" element={
                <ProtectedRoute>
                    <Dashboard>
                        <PreReceiptConvert />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/receiving/create" element={
                <ProtectedRoute>
                    <Dashboard>
                        <ReceivingCreate />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/prereceiving/create" element={
                <ProtectedRoute>
                    <Dashboard>
                        <PrereceiptCreate />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/receiving/:id" element={
                <ProtectedRoute>
                    <Dashboard>
                        <ReceivingView />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/sales" element={
                <ProtectedRoute>
                    <Dashboard>
                        <Sales />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/sales/:id" element={
                <Dashboard>
                    <SaleView />
                </Dashboard>} />
            <Route path="/sales/create" element={
                <ProtectedRoute>
                    <Dashboard>
                        <SalesCreate />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/dashboard" element={
                <ProtectedRoute>
                    <Dashboard>
                        <Dash />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/fininstitutions" element={
                <ProtectedRoute>
                    <Dashboard>
                        <FinInstitution />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/fininstitutions/create" element={
                <ProtectedRoute>
                    <Dashboard>
                        <FinInstitutionCreate />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/fininstitutions/:id" element={
                <ProtectedRoute>
                    <Dashboard>
                        <FinInstitutionView />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/exporters" element={
                <ProtectedRoute>
                    <Dashboard>
                        <Exporter />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/exporters/create" element={
                <ProtectedRoute>
                    <Dashboard>
                        <ExporterCreate />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/exporters/:id" element={
                <ProtectedRoute>
                    <Dashboard>
                        <ExporterView />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/importers" element={
                <ProtectedRoute>
                    <Dashboard>
                        <Importer />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/importers/create" element={
                <ProtectedRoute>
                    <Dashboard>
                        <ImporterCreate />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/importers/:id" element={
                <ProtectedRoute>
                    <Dashboard>
                        <ImporterView />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/users/create" element={
                <ProtectedRoute>
                    <Dashboard>
                        <UsersCreate />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/users/:id" element={
                <ProtectedRoute>
                    <Dashboard>
                        <UserView />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route exact path="/products" element={
                <ProtectedRoute>
                    <Dashboard>
                        <Product />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/products/create" element={
                <ProtectedRoute>
                    <Dashboard>
                        <ProductCreate />
                    </Dashboard>
                </ProtectedRoute>} />
            <Route path="/products/:id" element={
                <ProtectedRoute>
                    <Dashboard>
                        <ProductView />
                    </Dashboard>
                </ProtectedRoute>} />

            {isBasic() ? <Route path="*" element={<Navigate to="/accs" />} /> :
                <Route path="*" element={<Navigate to="/dashboard" />} />
            }
        </Routes>
    </Router>
)

export default Routess;
