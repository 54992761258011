import * as React from 'react';
import { IMaskInput } from 'react-imask';

const CnpjTextMask = React.forwardRef(function CnpjTextMask(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="##.###.###/####-##"
            definitions={{
                '#': /[0-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

const MoneyTextMask4 = React.forwardRef(function MoneyTextMask4(props, ref) {
  const { onChange, ...other } = props;
  return (
      <IMaskInput
          {...other}
          mask={Number}
          scale={4}
          radix=","
          thousandsSeparator="."
          inputRef={ref}
          onAccept={(value) => onChange({ target: { name: props.name, value } })}
          overwrite
      />
  );
});

const MoneyTextMask = React.forwardRef(function MoneyTextMask(props, ref) {
  const { onChange, ...other } = props;
  return (
      <IMaskInput
          {...other}
          mask={Number}
          radix=","
          thousandsSeparator="."
          inputRef={ref}
          onAccept={(value) => onChange({ target: { name: props.name, value } })}
          overwrite
      />
  );
});

const MoneyDolarTextMask = React.forwardRef(function MoneyDolarTextMask(props, ref) {
  const { onChange, ...other } = props;
  return (
      <IMaskInput
          {...other}
          mask={Number}
          radix="."
          thousandsSeparator=","
          inputRef={ref}
          onAccept={(value) => onChange({ target: { name: props.name, value } })}
          overwrite
      />
  );
});

// create a mask for date on format dd/mm/yyyy
const DateTextMask = React.forwardRef(function DateTextMask(props, ref) {
  const { onChange, ...other } = props;
  return (

      <IMaskInput
          {...other}
          mask={Date}
          separator="/"
          inputRef={ref}
          onAccept={(value) => onChange({ target: { name: props.name, value } })}
          overwrite
      />
  );
});

const TelTextMask = React.forwardRef(function TelTextMask(props, ref) {
  const { onChange, ...other } = props;
  return (
      <IMaskInput
          {...other}
          mask="(##)####-####"
          definitions={{
              '#': /[0-9]/,
          }}
          inputRef={ref}
          onAccept={(value) => onChange({ target: { name: props.name, value } })}
          overwrite
      />
  );
});

const InterCelTextMask = React.forwardRef(function CelTextMask(props, ref) {
  const { onChange, ...other } = props;
  return (
      <IMaskInput
          {...other}
          mask="####################"
          definitions={{
              '#': /[0-9-()]/,
          }}
          inputRef={ref}
          onAccept={(value) => onChange({ target: { name: props.name, value } })}
          overwrite
      />
  );
});

const CelTextMask = React.forwardRef(function CelTextMask(props, ref) {
  const { onChange, ...other } = props;
  return (
      <IMaskInput
          {...other}
          mask="(##)#####-####"
          definitions={{
              '#': /[0-9]/,
          }}
          inputRef={ref}
          onAccept={(value) => onChange({ target: { name: props.name, value } })}
          overwrite
      />
  );
});

const NumberTextMask = React.forwardRef(function NumberTextMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
          {...other}
          mask="######"
          definitions={{
              '#': /[0-9]/,
          }}
          inputRef={ref}
          onAccept={(value) => onChange({ target: { name: props.name, value } })}
          overwrite
      />
  )
});

const ContractNumberMask = React.forwardRef(function ContractNumberMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
          {...other}
          mask="############"
          definitions={{
              '#': /[0-9]/,
          }}
          inputRef={ref}
          onAccept={(value) => onChange({ target: { name: props.name, value } })}
          overwrite
      />
  )
});

const CodeTextMask = React.forwardRef(function NumberTextMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
          {...other}
          mask="########"
          definitions={{
              '#': /[0-9A-Za-z-/]/,
          }}
          inputRef={ref}
          onAccept={(value) => onChange({ target: { name: props.name, value } })}
          overwrite
      />
  )
});

const IdentifierTextMask = React.forwardRef(function NumberTextMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
          {...other}
          mask="############"
          definitions={{
              '#': /[0-9]/,
          }}
          inputRef={ref}
          onAccept={(value) => onChange({ target: { name: props.name, value } })}
          overwrite
      />
  )
});

// Component to mask CEP
const CepTextMask = React.forwardRef(function CepTextMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
          {...other}
          mask="#####-###"
          definitions={{
              '#': /[0-9]/,
          }}
          inputRef={ref}
          onAccept={(value) => onChange({ target: { name: props.name, value } })}
          overwrite
      />
  )
});

// Component to mask email
const EmailTextMask = React.forwardRef(function EmailTextMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
          {...other}
          mask={emailRegex}
          inputRef={ref}
          onAccept={(value) => onChange({ target: { name: props.name, value } })}
          overwrite
      />
  )
});

// Component to mask alpha
const AlphaTextMask = React.forwardRef(function AlphaTextMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
          {...other}
          mask={/^[a-zA-Z ]{1,60}$/}
          inputRef={ref}
          onAccept={(value) => onChange({ target: { name: props.name, value } })}
          overwrite
      />
  )
});

// regex pattern to validate emails
const emailRegex = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export {
  CnpjTextMask,
  TelTextMask,
  CelTextMask,
  NumberTextMask,
  CepTextMask,
  EmailTextMask,
  AlphaTextMask,
  MoneyTextMask,
  MoneyDolarTextMask,
  DateTextMask,
  IdentifierTextMask,
  CodeTextMask,
  InterCelTextMask,
  MoneyTextMask4,
  ContractNumberMask
};