export function formatFloatToDolar(value) {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export const sumReceivedValues = (list) => {
    let total = 0;
    if (!list || list.length === 0) {
        return total;
    }
    list.forEach(element => {
        total += element.valorRecebido;
    });
    return total;
}

export const sumPreReceivedValues = (list) => {
    let total = 0;
    if (!list || list.length === 0) {
        return total;
    }
    list.forEach(element => {
        total += element.valorTotal;
    });
    return total;
}

export const sumPreReceivedValuesClosed = (list) => {
    let total = 0;
    if (!list || list.length === 0) {
        return total;
    }
    list.forEach(element => {
        total += element.valorTotal - element.valorRecebido;
    });
    return total;
}

export function formatFloatToReal(value) {
    if (!value) {
        return '0,00';
    }
    return value.toLocaleString('pt-BR', { currency: 'BRL', minimumFractionDigits: 2 });
}

export function formatRealToFloat(value) {
    return parseFloat(value.replace('.', '').replace(',', '.'));
}

export const realToFloat = (value) => {
    let newValue = value.replace(/\./g, "");
    newValue = newValue.replace(",", ".");
    return parseFloat(newValue);
}