import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Grid,
    Typography,
    Paper,
    Box,
    Button,
    Fade
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import { Add, Edit, Remove } from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import dayjs from 'dayjs';

import { api } from '../../services/api';

import {
    IdentifierTextMask,
    NumberTextMask,
    InterCelTextMask
} from '../../components/masks';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const ImporterView = (props) => {
    const { id } = useParams();
    const [fields, setFields] = useState({ "contatos": [] });
    const [fieldsBack, setFieldsBack] = useState({ "contatos": []});
    const navigate = useNavigate();

    const [editing, setEditing] = useState(false);
    const [running, setRunning] = useState(false)
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const handleAddAttr = (target, idx, field) => {
        let contatosList = fields.contatos;
        contatosList[idx][field] = target;
        setFields((fields) => ({
            ...fields, contatos: contatosList
        }));
    };

    const updateImporter = async (item, id) => {
        const resp = api.putImporter(JSON.stringify(item), id);
        return resp;
    }

    const deleteImporter = async id => {
        const resp = api.delImporter(id);
        return resp;
    }

    const handleClose = () => {
        setRunning(false);
        setOpen(false);
    };

    const handleDelete = async id => {
        setRunning(true);
        const resp = await deleteImporter(id);
        if (resp) {
            setRunning(false);
            navigate(-1);
        } else {
            setRunning(false);
            setMessage("Erro ao excluir cliente!");
            setSeverity("error");
        }
    }

    const handleEdit = () => {
        setEditing(true);
    }

    const handleSubmit = async e => {
        setRunning(true);
        e.preventDefault();
        const resp = await updateImporter(fields, id);
        if (resp) {
            setMessage("Cliente atualizado!");
            setSeverity("success");
            setFields(resp);
            setFieldsBack(resp);
        } else {
            setMessage("Erro ao atualizar cliente!");
            setSeverity("error");
        }
        setOpen(true);
        setRunning(false);
        setEditing(false);
    }

    const cancelSubmit = () => {
        setFields(fieldsBack);
        setEditing(false);
    }

    useEffect(() => {
        let mounted = true;
        api.getOneImporter(id)
            .then(resp => {
                if (mounted) {
                    setFields({
                        ...resp,
                        contatos: resp.contatos ? resp.contatos : []
                    });
                    setFields({
                        ...resp,
                        contatos: resp.contatos ? resp.contatos : []
                    });
                }
            })
        return () => mounted = false;
    }, [])

    return (
        <Container
            maxWidth="xg"
            sx={{ mt: 4, mb: 4 }}
            component="form"
            onSubmit={handleSubmit}>
            <Grid item xs={12}>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar
                        open={open}
                        autoHideDuration={2000}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}>
                        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>
                </Stack>

                <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 }, borderRadius: '10px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Button
                                style={{ textTransform: 'none', fontSize: '12px', marginBottom: "20px" }}
                                startIcon={(<Edit style={{ fontSize: "12px" }} />)}
                                sx={{ mr: 2 }}
                                size="small"
                                variant="contained"
                                disabled={editing || running}
                                disableElevation
                                onClick={() => handleEdit(id)}
                            >
                                Editar
                            </Button>
                            <Accordion expanded={true} style={{ borderRadius: '10px' }}>
                                <AccordionSummary sx={{ height: "40px" }} expandIcon={false} style={{ minHeight: "0px", borderRadius: '10px', backgroundColor: "#fff" }} id="panel-tel-header">
                                    <Typography sx={{ fontSize: "12px" }}>Informações Gerais</Typography>

                                </AccordionSummary>
                                <AccordionDetails style={{ borderColor: "#fff" }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                size='small'
                                                disabled={running}
                                                InputProps={{
                                                    readOnly: !editing,
                                                    style: { fontSize: "12px" },
                                                    sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                                }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                inputProps={{
                                                    maxLength: 60,
                                                }}
                                                id="fullName"
                                                name="fullName"
                                                label="Nome/Razão Social"
                                                value={fields.razaoSocial}
                                                onChange={event => setFields({ ...fields, razaoSocial: event.target.value })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size='small'
                                                disabled={running}
                                                InputProps={{
                                                    inputComponent: IdentifierTextMask,
                                                    readOnly: !editing,
                                                    style: { fontSize: "12px" },
                                                    sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                                }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                id="cnpj"
                                                name="cnpj"
                                                label="CNPJ"
                                                value={fields.cnpj}
                                                onChange={event => setFields({ ...fields, cnpj: event.target.value })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size='small'
                                                required
                                                disabled={running}
                                                InputProps={{ readOnly: !editing, style: { fontSize: "12px" }, sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' } }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                inputProps={{
                                                    maxLength: 15
                                                }}
                                                id="taxId"
                                                name="taxId"
                                                label="Tax ID"
                                                value={fields.taxId}
                                                onChange={event => setFields({ ...fields, taxId: event.target.value })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size='small'
                                                disabled={running}
                                                InputProps={{ readOnly: !editing, style: { fontSize: "12px" }, sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' } }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                inputProps={{
                                                    maxLength: 15
                                                }}
                                                id="codigoERP"
                                                name="codigoERP"
                                                label="Código ERP"
                                                value={fields.codigoERP}
                                                onChange={event => setFields({ ...fields, codigoERP: event.target.value })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size='small'
                                                disabled={running}
                                                InputProps={{ readOnly: !editing, style: { fontSize: "12px" }, sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' } }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                id="status"
                                                name="status"
                                                label={fields.inativoDesde ? "Inativo Desde" : "Ativo Desde"}
                                                value={fields.inativoDesde ? dayjs(fields.inativoDesde).format('DD/MM/YYYY').toString() : dayjs(fields.ativoDesde).format('DD/MM/YYYY').toString()}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Accordion expanded={true} style={{ borderRadius: '10px' }}>
                                <AccordionSummary sx={{ height: "40px" }} expandIcon={false} style={{ minHeight: "0px", borderRadius: '10px', backgroundColor: "#fff" }} id="panel-tel-header">
                                    <Typography sx={{ fontSize: "12px" }}>Endereço</Typography>

                                </AccordionSummary>
                                <AccordionDetails style={{ borderColor: "#fff" }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                size='small'
                                                required
                                                disabled={running}
                                                InputProps={{ readOnly: !editing, style: { fontSize: "12px" }, sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' } }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                inputProps={{
                                                    maxLength: 60
                                                }}
                                                id="logradouro"
                                                name="logradouro"
                                                label="Logradouro"
                                                value={fields.endereco && fields.endereco.logradouro}
                                                onChange={event => setFields({ ...fields, endereco: { ...fields.endereco, logradouro: event.target.value } })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size='small'
                                                required
                                                disabled={running}
                                                InputProps={{
                                                    inputComponent: NumberTextMask,
                                                    readOnly: !editing,
                                                    style: { fontSize: "12px" },
                                                    sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                                }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                id="numero"
                                                name="numero"
                                                label="Número"
                                                value={fields.endereco && fields.endereco.numero}
                                                onChange={event => setFields({ ...fields, endereco: { ...fields.endereco, numero: event.target.value } })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                size='small'
                                                disabled={running}
                                                InputProps={{ readOnly: !editing, style: { fontSize: "12px" }, sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' } }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                inputProps={{
                                                    maxLength: 30
                                                }}
                                                id="bairro"
                                                name="bairro"
                                                label="Bairro"
                                                value={fields.endereco && fields.endereco.bairro}
                                                onChange={event => setFields({ ...fields, endereco: { ...fields.endereco, bairro: event.target.value } })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                size='small'
                                                disabled={running}
                                                InputProps={{ readOnly: !editing, style: { fontSize: "12px" }, sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' } }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                inputProps={{
                                                    maxLength: 20
                                                }}
                                                id="complemento"
                                                name="complemento"
                                                label="Complemento"
                                                value={fields.endereco && fields.endereco.complemento}
                                                onChange={event => setFields({ ...fields, endereco: { ...fields.endereco, complemento: event.target.value } })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                size='small'
                                                required
                                                disabled={running}
                                                InputProps={{ readOnly: !editing, style: { fontSize: "12px" }, sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' } }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                inputProps={{
                                                    maxLength: 30
                                                }}
                                                id="cidade"
                                                name="cidade"
                                                label="Cidade"
                                                value={fields.endereco && fields.endereco.cidade}
                                                onChange={event => setFields({ ...fields, endereco: { ...fields.endereco, cidade: event.target.value } })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size='small'
                                                required
                                                disabled={running}
                                                InputProps={{ readOnly: !editing, style: { fontSize: "12px" }, sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' } }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                inputProps={{
                                                    maxLength: 20
                                                }}
                                                id="estado"
                                                name="estado"
                                                label="Estado"
                                                value={fields.endereco && fields.endereco.estado}
                                                onChange={event => setFields({ ...fields, endereco: { ...fields.endereco, estado: event.target.value } })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size='small'
                                                required
                                                disabled={running}
                                                InputProps={{ readOnly: !editing, style: { fontSize: "12px" }, sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' } }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                inputProps={{
                                                    maxLength: 20
                                                }}
                                                id="pais"
                                                name="pais"
                                                label="País"
                                                value={fields.endereco && fields.endereco.pais}
                                                onChange={event => setFields({ ...fields, endereco: { ...fields.endereco, pais: event.target.value } })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size='small'
                                                disabled={running}
                                                InputProps={{ readOnly: !editing, style: { fontSize: "12px" }, sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' } }}
                                                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                inputProps={{
                                                    maxLength: 10
                                                }}
                                                id="zipCode"
                                                name="zipCode"
                                                label="Zip Code"
                                                value={fields.endereco && fields.endereco.zipCode}
                                                onChange={event => setFields({ ...fields, endereco: { ...fields.endereco, zipCode: event.target.value } })}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Accordion expanded={true} style={{ borderRadius: '10px' }}>
                                <AccordionSummary sx={{ height: "40px" }} expandIcon={false} style={{ minHeight: "0px", borderRadius: '10px', backgroundColor: "#fff" }} id="panel-tel-header">
                                    <Typography sx={{ fontSize: "12px" }}>Contatos</Typography>

                                </AccordionSummary>
                                <AccordionDetails style={{ borderColor: "#fff" }}>
                                    {editing && <Grid item xs={12} sm={12} marginBottom={1}>
                                        <ButtonGroup disabled={running} style={{ height: "14px" }} variant="outlined" size="small" aria-label="small button group">
                                            <Button
                                                disableElevation
                                                onClick={() => {
                                                    let contatoList = fields.contatos;
                                                    contatoList.push({
                                                        "nome": "",
                                                        "email": "",
                                                        "celular": "",
                                                        "telefone": ""
                                                    });
                                                    setFields({ ...fields, contatos: contatoList });
                                                }}>
                                                <Add style={{ fontSize: "12px" }} /></Button>
                                            <Button
                                                disableElevation
                                                onClick={() => {
                                                    if (fields.contatos.length > 1) {
                                                        let contatoList = fields.contatos;
                                                        contatoList.pop();
                                                        setFields({ ...fields, contatos: contatoList });
                                                    }
                                                }}>
                                                <Remove style={{ fontSize: "12px" }} /></Button>
                                        </ButtonGroup>
                                    </Grid>}

                                    <Grid container spacing={3}>
                                        {fields.contatos.map((field, idx) => {
                                            return (
                                                <React.Fragment key={idx}>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            size="small"
                                                            disabled={running}
                                                            InputProps={{ readOnly: !editing, style: { fontSize: "12px" }, sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' } }}
                                                            InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                            required
                                                            id={`"nome_"${idx}`}
                                                            name="nome"
                                                            label="Nome"
                                                            value={field["nome"]}
                                                            onChange={event => handleAddAttr(event.target.value, idx, "nome")}
                                                            inputProps={{
                                                                maxLength: 40
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            size="small"
                                                            disabled={running}
                                                            InputProps={{ readOnly: !editing, style: { fontSize: "12px" }, sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' } }}
                                                            InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                            id={`"email_"${idx}`}
                                                            name="email"
                                                            label="Email"
                                                            value={field["email"]}
                                                            onChange={event => handleAddAttr(event.target.value, idx, "email")}
                                                            inputProps={{
                                                                maxLength: 50
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <TextField
                                                            size="small"
                                                            disabled={running}
                                                            InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                            id={`"telefone_"${idx}`}
                                                            name="telefone"
                                                            label="Telefone"
                                                            value={field["telefone"]}
                                                            onChange={event => handleAddAttr(event.target.value, idx, "telefone")}
                                                            InputProps={{
                                                                readOnly: !editing,
                                                                inputComponent: InterCelTextMask,
                                                                style: { fontSize: "12px" },
                                                                sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <TextField
                                                            size="small"
                                                            disabled={running}
                                                            InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                                            id={`"celular_"${idx}`}
                                                            name="celular"
                                                            label="Celular"
                                                            value={field["celular"]}
                                                            onChange={event => handleAddAttr(event.target.value, idx, "celular")}
                                                            InputProps={{
                                                                readOnly: !editing,
                                                                inputComponent: InterCelTextMask,
                                                                style: { fontSize: "12px" },
                                                                sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </React.Fragment>
                                            )
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {editing ?
                        <Grid item >
                            <Grid container justifyContent="center">
                                <Grid item xs={12} sm={6}>
                                    <LoadingButton
                                        style={{ textTransform: 'none', fontSize: '12px' }}
                                        loading={running}
                                        type="submit"
                                        startIcon={(<SaveIcon style={{ fontSize: "12px" }} />)}
                                        color="primary"
                                        variant="contained"
                                        size="medium"
                                        disableElevation
                                    // sx={{ width: "100px" }}
                                    >
                                        Salvar
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <LoadingButton
                                        style={{ textTransform: 'none', fontSize: '12px' }}
                                        loading={running}
                                        startIcon={(<CancelIcon style={{ fontSize: "12px" }} />)}
                                        color="primary"
                                        variant='outlined'
                                        // variant="outlined"
                                        size="medium"
                                        disableElevation
                                        onClick={() => cancelSubmit()}
                                    // sx={{ width: "100px" }}
                                    >
                                        Cancelar
                                    </LoadingButton>
                                </Grid>
                            </Grid>

                        </Grid>
                        : null}
                    </Grid>
                </Paper>
            </Grid>
        </Container>
    );
}

export default ImporterView;
