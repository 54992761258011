import { useQuery } from "react-query";
import { axiosClient } from "../query/axiosClient";
import { PRERECEIPT } from "../configs/endpoints";

const fetchPrereceipt = async (id) => {
    const url = PRERECEIPT.PRERECEIPT_ONE(id);
    const { data } = await axiosClient.get(url);
    return data;
}

export const usePrereceipt = (id) => {
    return useQuery({
        queryKey: ["prereceipt", id],
        queryFn: () => fetchPrereceipt(id),
        refetchOnWindowFocus: "always",
        refetchOnMount: "always",
        cacheTime: 0,
    });
}

const fetchPrereceiptsList = async (pageSize, pageIndex) => {
    const url = PRERECEIPT.PRERECEIPT_LIST(pageSize, pageIndex);
    const { data } = await axiosClient.get(url);
    return data;
};

export const usePrereceiptsList = (pageSize, pageIndex) => {
    return useQuery({
        queryKey: ["prereceipts", pageSize, pageIndex],
        queryFn: () => fetchPrereceiptsList(pageSize, pageIndex),
        refetchOnWindowFocus: "always",
        refetchOnMount: "always",
        cacheTime: 0,

    });
}

export const postPrereceipt = async (prereceipt) => {
    const url = PRERECEIPT.PRERECEIPT_CREATE;
    try {
        const { data } = await axiosClient.post(url, prereceipt,
            {
                headers: {
                    'Content-Type': 'multipart/form-data/json'
                }
            });
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getFilePreReceipt = async (id, filename) => {
    const url = PRERECEIPT.PRERECEIPT_FILE(id, filename);
    try {
        const resp = await axiosClient.get(url);
        if (resp.status === 200) {
            return resp.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const deletePrereceipt = async (id) => {
    const url = PRERECEIPT.PRERECEIPT_DELETE(id);
    const resp = await axiosClient.delete(url);
    return resp;
}

// export const usePostPrereceipt = (prereceipt) => {
//     return useMutation(prereceipt, {
//         mutationKey: ["postPrereceipt", prereceipt],
//         mutationFn: () => postPrereceipt(prereceipt),
//         onError: (error) => {
//             console.log('ERRO', error);
//             // wait 5 minutes
//             setTimeout(() => {
//                 console.log('ERRO', error);
//             }, 300000);

//         }
//     });
// }