import React from 'react';
import {
    Container,
    Grid,
    Paper
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import PageHeader from './pageHeader';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

export default function PageCreate(props) {

    const handleClose = () => {
        props.setRunning(false);
        props.setOpen(false);
    };

    return (
        <Container component="form"
            maxWidth="xg"
            sx={{ mt: 4, mb: 4 }}
            onSubmit={props.handleSubmit}>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={props.open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    <Alert onClose={handleClose} severity={props.severity} sx={{ width: '100%' }}>
                        {props.message}
                    </Alert>
                </Snackbar>
            </Stack>
            <PageHeader title={props.title}></PageHeader>
            <Grid item xs={12}>
                <Paper variant="outlined" sx={{ p: { xs: 2, md: 2 }, borderRadius: '10px' }}>
                    <Grid container>
                        {props.children}
                        <Grid item xs={12} sm={12}>
                            <Grid container sx={{ m: 1}}>
                                <LoadingButton
                                    disabled={props.running || props.blockSend}
                                    style={{ textTransform: 'none', fontSize: '11px' }}
                                    loading={props.running}
                                    type="submit"
                                    startIcon={(<SaveIcon style={{ fontSize: "10px" }} />)}
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                >
                                    Salvar
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Container>
    );
}
