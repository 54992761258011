import { useQuery } from "react-query";
import { axiosClient } from "../query/axiosClient";
import { SALES } from "../configs/endpoints";

const fetchSales = async (pageSize, pageIndex) => {
    const url = SALES.SALES_LIST(pageSize, pageIndex);
    const { data } = await axiosClient.get(url);
    return data;
}

export const useSales = (pageSize, pageIndex) => {
    return useQuery({
        queryKey: ["sales", pageSize, pageIndex],
        queryFn: () => fetchSales(pageSize, pageIndex),
        refetchOnWindowFocus: "always",
        refetchOnMount: "always",
    });
}

const fetchSalesOpen = async (pageSize, pageIndex) => {
    const url = SALES.SALES_OPEN_LIST(pageSize, pageIndex);
    const { data } = await axiosClient.get(url);
    return data;
}

export const useSalesOpen = (pageSize, pageIndex) => {
    return useQuery({
        queryKey: ["salesOpen", pageSize, pageIndex],
        queryFn: () => fetchSalesOpen(pageSize, pageIndex),
        refetchOnWindowFocus: "always",
        refetchOnMount: "always",
    });
}

const fetchSale = async (id) => {
    const url = SALES.SALE_ONE(id);
    const { data } = await axiosClient.get(url);
    return data;
}

export const useSale = (id) => {
    return useQuery({
        queryKey: ["sale", id],
        queryFn: () => fetchSale(id),
        enabled: !!id,
        cacheTime: 0,
    });
}