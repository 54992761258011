import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import { Add, Remove } from "@mui/icons-material";
import { CelTextMask, InterCelTextMask } from "../masks";
import FormCard from "./formCard";

export default function FormContacts(props) {
    const { inputFields, setInputFields, running } = props;
    const fontSize = "12px";

    const handleAddAttr = (target, idx, field) => {
        let contatosList = inputFields.contatos;
        contatosList[idx][field] = target;
        setInputFields((inputFields) => ({
            ...inputFields, contatos: contatosList
        }));
    };

    return (
        <Grid item xs={12} sm={12}>
            <FormCard title="Contatos">
                <Grid item xs={12} sm={4} marginBottom={1}>
                    <ButtonGroup disabled={running} style={{ height: "14px" }} variant="outlined" size="small" aria-label="small button group">
                        <Button
                            disableElevation
                            onClick={() => {
                                let contatoList = inputFields.contatos;
                                contatoList.push({
                                    "nome": "",
                                    "email": "",
                                    "celular": "",
                                    "telefone": ""
                                });
                                setInputFields({ ...inputFields, contatos: contatoList });
                            }}>
                            <Add style={{ fontSize: fontSize }} /></Button>
                        <Button
                            disableElevation
                            onClick={() => {
                                if (inputFields.contatos.length > 1) {
                                    let contatoList = inputFields.contatos;
                                    contatoList.pop();
                                    setInputFields({ ...inputFields, contatos: contatoList });
                                }
                            }}>
                            <Remove style={{ fontSize: fontSize }} /></Button>
                    </ButtonGroup>
                </Grid>
                <Grid container spacing={1}>
                    {inputFields.contatos.map((field, idx) => {
                        return (
                            <React.Fragment key={idx}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        autoComplete="on"
                                        size="small"
                                        required
                                        disabled={running}
                                        id={`"name_"${idx}`}
                                        name="name"
                                        label="Nome"
                                        value={inputFields.contatos[idx]["nome"]}
                                        onChange={event => handleAddAttr(event.target.value, idx, "nome")}
                                        InputProps={{ style: { fontSize: fontSize } }}
                                        InputLabelProps={{ style: { fontSize: fontSize } }}
                                        inputProps={{
                                            maxLength: 40
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        autoComplete="on"
                                        size="small"
                                        disabled={running}
                                        id={`"emai_contact_"${idx}`}
                                        name="email"
                                        label="Email"
                                        value={inputFields.contatos[idx]["email"]}
                                        onChange={event => handleAddAttr(event.target.value, idx, "email")}
                                        InputProps={{ style: { fontSize: fontSize } }}
                                        InputLabelProps={{ style: { fontSize: fontSize } }}
                                        inputProps={{
                                            maxLength: 50
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <TextField
                                        autoComplete="on"
                                        size="small"
                                        disabled={running}
                                        id={`"tel_"${idx}`}
                                        name="tel"
                                        label="Telefone"
                                        value={inputFields.contatos[idx]["telefone"]}
                                        onChange={event => handleAddAttr(event.target.value, idx, "telefone")}
                                        InputLabelProps={{ style: { fontSize: fontSize } }}
                                        InputProps={{
                                            style: { fontSize: fontSize },
                                            inputComponent: InterCelTextMask
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <TextField
                                        autoComplete="on"
                                        size="small"
                                        disabled={running}
                                        id={`"cel_"${idx}`}
                                        name="cel"
                                        label="Celular"
                                        value={inputFields.contatos[idx]["celular"]}
                                        onChange={event => handleAddAttr(event.target.value, idx, "celular")}
                                        InputLabelProps={{ style: { fontSize: fontSize } }}
                                        InputProps={{
                                            style: { fontSize: fontSize },
                                            inputComponent: CelTextMask
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </React.Fragment>
                        )
                    })}
                </Grid>
            </FormCard>
        </Grid>
    );
}