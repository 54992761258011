import React, { useState } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

export default function ValueCard(props) {
    const message = props.message;
    const values = props.values;
    return (
        <Box xs={{ minWidth: 400 }}>
            <Card variant="outlined" sx={{ borderRadius: '10px' }}>
                <CardContent>
                    <Grid container alignItems="center">
                        <Grid item xs={10}>
                            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                {message}
                            </Typography>
                            <Typography sx={{ fontSize: "30px"}} component="p" variant="h3">
                                {values}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            {props.children}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
}