import { Apartment, Business, Dashboard, Inventory } from '@mui/icons-material';

import BarChartIcon from '@mui/icons-material/BarChart';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import React from 'react';
import { Typography } from '@mui/material';
import { checkRole } from '../../services/auth';
import { useNavigate } from "react-router-dom";

const fontSize = "12px";

const MainListItems = props => {
  const navigate = useNavigate();

  function goToHome() {
    navigate("/users");
  }

  function goToSale() {
    navigate("/sales");
  }

  function goToDash() {
    navigate("/dashboard");
  }

  function goToFinInstitution() {
    navigate("/fininstitutions")
  }

  function goToProducts() {
    navigate("/products")
  }

  function goToExporters() {
    navigate("/exporters")
  }

  function goToImporters() {
    navigate("/importers")
  }

  function goToAccs() {
    navigate("/accs")
  }

  function goToReceiving() {
    navigate("/receiving")
  }

  function goToPreReceiving() {
    navigate("/prereceiving")
  }

  return (
    <React.Fragment>

      { checkRole(["ADMIN", "GERENTE"]) && <ListItemButton onClick={() => goToDash()} >
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        <ListItemText primary={<Typography style={{ fontSize: fontSize }}>Dashboard</Typography>}/>
      </ListItemButton> }

      <ListItemButton onClick={() => goToAccs()} >
        <ListItemIcon>
          <InsertChartIcon />
        </ListItemIcon>
        <ListItemText primary={<Typography style={{ fontSize: fontSize }}>Accs</Typography>}/>
      </ListItemButton>

      <ListItemButton onClick={() => goToSale()} >
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary={<Typography style={{ fontSize: fontSize }}>Vendas</Typography>}/>
      </ListItemButton>

      <ListItemButton onClick={() => goToReceiving()} >
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary={<Typography style={{ fontSize: fontSize }}>Recebimentos</Typography>}/>
      </ListItemButton>

      <ListItemButton onClick={() => goToPreReceiving()} >
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary={<Typography style={{ fontSize: fontSize }}>Pré Recebimentos</Typography>}/>
      </ListItemButton>

      { checkRole(["ADMIN", "GERENTE"]) &&  <ListItemButton onClick={() => goToFinInstitution()} >
        <ListItemIcon>
          <Apartment />
        </ListItemIcon>
        <ListItemText primary={<Typography style={{ fontSize: fontSize }}>Bancos</Typography>}/>
      </ListItemButton> }

      { checkRole(["ADMIN", "GERENTE"]) &&  <ListItemButton onClick={() => goToExporters()} >
        <ListItemIcon>
          <Business />
        </ListItemIcon>
        <ListItemText primary={<Typography style={{ fontSize: fontSize }}>Exportadoras</Typography>}/>
      </ListItemButton> }

      { checkRole(["ADMIN", "GERENTE"]) &&  <ListItemButton onClick={() => goToImporters()} >
        <ListItemIcon>
          <Business />
        </ListItemIcon>
        <ListItemText primary={<Typography style={{ fontSize: fontSize }}>Clientes</Typography>}/>
      </ListItemButton> }

      { checkRole(["ADMIN", "GERENTE"]) && <ListItemButton onClick={() => goToProducts()} >
        <ListItemIcon>
          <Inventory />
        </ListItemIcon>
        <ListItemText primary={<Typography style={{ fontSize: fontSize }}>Produtos</Typography>}/>
      </ListItemButton> }

      { checkRole(["ADMIN"]) && <ListItemButton onClick={() => goToHome()} >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary={<Typography style={{ fontSize: fontSize }}>Usuários</Typography>}/>
      </ListItemButton> }

    </React.Fragment>

  )
}

export default MainListItems;