import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isAdmin, isManager } from '../../services/auth';

import ExporterTable from "./exporterTable";
import PageHeaderButton from '../../components/pageHeaderButton';
import { api } from '../../services/api';

const Exporter = props => {
    const [exproterList, setExporterList] = useState([]);

    useEffect(() => {
        let mounted = true;
        api.getExporters()
            .then(resp => {
                if (mounted && resp) {
                    setExporterList(resp)
                }
            })
        return () => mounted = false;
    }, [])

    return (
        <Container maxWidth="xg" sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={12}>
                <PageHeaderButton canCreate={isAdmin() || isManager()} title="Exportadoras" path="/exporters" ></PageHeaderButton>
            </Grid>
            <Grid item xs={12}>
                <ExporterTable rows={exproterList}/>
            </Grid>
        </Container>
    );

};

export default Exporter;