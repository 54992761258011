import React, { useEffect, useState } from "react";
import {
    Container,
    Grid,
    Paper,
    TextField,
    Autocomplete,
    RadioGroup,
    Radio,
    Typography,
} from "@mui/material";
import { usePrereceiptsList } from "../../apis/prereceipt";
import { isAdmin, isManager } from "../../services/auth";
import dayjs from "dayjs";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import PageHeaderButton from "../../components/pageHeaderButton";
import PreReceivingTable from "./prereceivingTable";
import FormCard from "../../components/forms/formCard";

const PrereceiptList = () => {
    
    const [filterBy, setFilterBy] = useState("open");
    const [prereceipts, setPrereceipts] = useState([]);
    const [srcPrereceipts, setSrcPrereceipts] = useState([]);
    const [field, setField] = useState("");
    const [valuesList, setValuesList] = useState([]);
    const { data, isLoading, isFetched, refetch } = usePrereceiptsList(1000000000, 0);
    
    const mapFields = {
        "Kav": "vendaCode",
        "Valor Recebido": "valorRecebido",
        "Cliente": "importadora",
        "Data": "creationDate"
    }

    const handleFieldSelect = (value) => {
        if (value) {
            let valuesFiltered = [];
            if (mapFields[value] === "creationDate") {
                valuesFiltered = srcPrereceipts.map(elem => dayjs(elem[mapFields[value]]).format('DD/MM/YYYY').toString());
            } else {
                valuesFiltered = srcPrereceipts.map(elem => elem[mapFields[value]]);
            }
            setValuesList(valuesFiltered.filter((item, index) => valuesFiltered.indexOf(item) === index));
            setField(mapFields[value]);
        }
    };

    const handleValueSelect = (value) => {
        if (value && value.length > 0) {
            let receivingFiltered = [];
            if (field === "creationDate") {
                receivingFiltered = srcPrereceipts.filter(a => value.includes(dayjs(a[field]).format('DD/MM/YYYY').toString()));
            } else {
                receivingFiltered = srcPrereceipts.filter(a => value.includes(a[field]));
            }
            setPrereceipts(receivingFiltered);
        } else {
            setPrereceipts(srcPrereceipts);
        }
    };
    
    useEffect(() => {
        if (isFetched && data?.page !== prereceipts) {
            if (filterBy === "open") {
                let filteredPrereceipts = data.page.filter(a => (a.valorTotal - a.valorRecebido).toFixed(2) > 0);
                setPrereceipts(filteredPrereceipts);
                setSrcPrereceipts(filteredPrereceipts);
            }
            if (filterBy === "closed") {
                let filteredPrereceipts = data.page.filter(a => (a.valorTotal - a.valorRecebido).toFixed(2) <= 0);
                setPrereceipts(filteredPrereceipts);
                setSrcPrereceipts(filteredPrereceipts);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isFetched, filterBy]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    // if (isFetched && data?.page !== prereceipts) {
    //     setPrereceipts(data.page);
    //     setSrcPrereceipts(data.page);
    // }

    const handleChangePriority = (event) => {
        if (event.target.value === "open") {
            setFilterBy("open");
            // let filteredPrereceipts = srcPrereceipts.filter(a => (a.valorTotal - a.valorRecebido).toFixed(2) > 0)
            // console.log(filteredPrereceipts);
            // setPrereceipts(filteredPrereceipts);
            // setSrcPrereceipts(filteredPrereceipts);
        }
        if (event.target.value === "closed") {
            setFilterBy("closed");
            // let filteredPrereceipts = srcPrereceipts.filter(a => (a.valorTotal - a.valorRecebido).toFixed(2) <= 0)
            // setPrereceipts(filteredPrereceipts);
            // setSrcPrereceipts(filteredPrereceipts);
        }
    };

    return (
        <Container maxWidth="xg" sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={12}>
                <PageHeaderButton
                    title="Pré Recebimentos"
                    path="/prereceiving"
                    canCreate={isAdmin() || isManager()}></PageHeaderButton>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 }, borderRadius: '10px' }}>
                        <Grid item xs={12} sm={12}>
                            <FormCard title="Filtros">
                                <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontSize: "12px" }}>Considerar pré-recebimentos:</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={handleChangePriority}
                                                defaultValue="open"
                                            >
                                                <FormControlLabel value="open" control={<Radio style={{ height: "5px" }} size='small' />}
                                                    label={<Typography style={{ fontSize: "12px" }}>Saldo disponível</Typography>} />
                                                <FormControlLabel value="closed" control={<Radio size='small' />} label={<Typography style={{ fontSize: "12px" }}>Quitados</Typography>} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Autocomplete
                                            size="small"
                                            disablePortal
                                            id="field"
                                            getOptionLabel={(option) => option}
                                            options={Object.keys(mapFields)}
                                            onChange={(event, value) => handleFieldSelect(value)}
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                                InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                                label="Campo a ser filtrado" />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <Autocomplete
                                            multiple
                                            size="small"
                                            disablePortal
                                            id="value"
                                            getOptionLabel={(option) => option}
                                            options={valuesList}
                                            onChange={(event, value) => handleValueSelect(value)}
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label="Conteúdo"
                                                InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                                InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                            />}
                                        />
                                    </Grid>
                                </Grid>
                            </FormCard>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <PreReceivingTable rows={prereceipts} />
                </Grid>
            </Grid>
        </Container>
    );
}

export default PrereceiptList;