import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isAdmin, isManager } from '../../services/auth';

import FinInstitutionTable from "./finInstitutionTable";
import PageHeaderButton from '../../components/pageHeaderButton';
import { api } from '../../services/api';

const FinInstitution = props => {
    const [institutionList, setInstitutionList] = useState([]);

    useEffect(() => {
        let mounted = true;
        api.getFinInstitutions()
            .then(resp => {
                if (mounted && resp) {
                    setInstitutionList(resp)
                }
            })
        return () => mounted = false;
    }, [])

    return (
        <Container maxWidth="xg" sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={12}>
                <PageHeaderButton canCreate={isAdmin() || isManager()} title="Instituições Financeiras" path="/fininstitutions" ></PageHeaderButton>
            </Grid>
            <Grid item xs={12}>
                <FinInstitutionTable rows={institutionList}/>
            </Grid>
        </Container>
    );

};

export default FinInstitution;