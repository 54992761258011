import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { CepTextMask, NumberTextMask } from "../masks";
import FormCard from "./formCard";

export default function FormNationalAddress(props) {
    const { inputFields, setInputFields, running } = props;
    const fontSize = "12px";

    return (
        <Grid item xs={12} sm={12}>
            <FormCard title="Endereço">
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            size='small'
                            required
                            disabled={running}
                            id="logradouro"
                            name="logradouro"
                            label="Logradouro"
                            value={inputFields.endereco.logradouro}
                            onChange={event => setInputFields(
                                { ...inputFields, endereco: { ...inputFields.endereco, logradouro: event.target.value } })}
                            InputProps={{ style: { fontSize: fontSize } }}
                            InputLabelProps={{ style: { fontSize: fontSize } }}
                            inputProps={{
                                maxLength: 60
                            }}
                            fullWidth

                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            size='small'
                            disabled={running}
                            id="numero"
                            name="numero"
                            label="Número"
                            value={inputFields.endereco.numero}
                            onChange={event => setInputFields(
                                { ...inputFields, endereco: { ...inputFields.endereco, numero: event.target.value } })}
                            InputLabelProps={{ style: { fontSize: fontSize } }}
                            InputProps={{
                                style: { fontSize: fontSize },
                                inputComponent: NumberTextMask
                            }}
                            fullWidth

                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size='small'
                            required
                            disabled={running}
                            id="bairro"
                            name="bairro"
                            label="Bairro"
                            value={inputFields.endereco.bairro}
                            onChange={event => setInputFields(
                                { ...inputFields, endereco: { ...inputFields.endereco, bairro: event.target.value } })}
                            InputProps={{ style: { fontSize: fontSize } }}
                            InputLabelProps={{ style: { fontSize: fontSize } }}
                            inputProps={{
                                maxLength: 30
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            size='small'
                            disabled={running}
                            id="complemento"
                            name="complemento"
                            label="Complemento"
                            value={inputFields.endereco.complemento}
                            onChange={event => setInputFields(
                                { ...inputFields, endereco: { ...inputFields.endereco, complemento: event.target.value } })}
                            InputProps={{ style: { fontSize: fontSize } }}
                            InputLabelProps={{ style: { fontSize: fontSize } }}
                            inputProps={{
                                maxLength: 20
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            size='small'
                            required
                            disabled={running}
                            id="cidade"
                            name="cidade"
                            label="Cidade"
                            value={inputFields.endereco.cidade}
                            onChange={event => setInputFields(
                                { ...inputFields, endereco: { ...inputFields.endereco, cidade: event.target.value } })}
                            InputProps={{ style: { fontSize: fontSize } }}
                            InputLabelProps={{ style: { fontSize: fontSize } }}
                            inputProps={{
                                maxLength: 30
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            size='small'
                            required
                            disabled={running}
                            id="estado"
                            name="estado"
                            label="Estado"
                            value={inputFields.endereco.estado}
                            onChange={event => setInputFields(
                                { ...inputFields, endereco: { ...inputFields.endereco, estado: event.target.value } })}
                            InputProps={{ style: { fontSize: fontSize } }}
                            InputLabelProps={{ style: { fontSize: fontSize } }}
                            inputProps={{
                                maxLength: 20
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="select-label">País</InputLabel>
                            <Select
                                size='small'
                                required
                                disabled={running}
                                id="pais"
                                label="Pais"
                                name="pais"
                                value={inputFields.endereco.pais}
                                onChange={event => setInputFields(
                                    { ...inputFields, endereco: { ...inputFields.endereco, pais: event.target.value } })}
                                fullWidth
                                inputprops={{ style: { fontSize: fontSize } }}
                                inputlabelprops={{ style: { fontSize: fontSize } }}
                            >
                                <MenuItem value="Brasil">Brasil</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            size='small'
                            required
                            disabled={running}
                            id="cep"
                            name="cep"
                            label="Cep"
                            value={inputFields.endereco.zipCode}
                            onChange={event => setInputFields(
                                { ...inputFields, endereco: { ...inputFields.endereco, zipCode: event.target.value } })}
                            InputLabelProps={{ style: { fontSize: fontSize } }}
                            InputProps={{
                                style: { fontSize: fontSize },
                                inputComponent: CepTextMask
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </FormCard>
        </Grid>
    );
}