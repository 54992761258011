import { combineReducers } from "@reduxjs/toolkit";


const rootReducer = combineReducers({
    data: () => (
        {
            institutionsList: [
                {
                    id: "1",
                    tipo: "Juridica",
                    nome_razao: "Banco ITAU",
                    emails: ["pessoa_um@itau.com.br", "pessoa_dois@itau.com.br"],
                    telefones: ["27999990000", "27999991111"],
                    cpf_cnpj:"31111222000146",
                    ativo: true,
                    ativo_desde: "01/02/2023",
                    inativo_desde: "",
                    contatos: [
                        {
                            nome: "Contato1",
                            email: "contato1@gmail.com",
                            telfone: "2733331111",
                            celular: "279999911111",
                        },
                        {
                            nome: "Contato2",
                            email: "contato2@gmail.com",
                            telfone: "2744441111",
                            celular: "278888811111",
                        },
                        {
                            nome: "Contato3",
                            email: "contato3@gmail.com",
                            telfone: "2744441111",
                            celular: "278888811111",
                        }
                    ],
                    endereco: {
                        logradouro: "Av. Dante Michelini, 1423",
                        complemento: "",
                        bairro: "Jardim da Penha",
                        cidade: "Vitoria",
                        estado: "Espirito Santo",
                        pais: "Brasil",
                        cep_zip: "29060235",
                        caixa_postal: "11112536"
                    }

                },
                {
                    id: "2",
                    tipo: "Juridica",
                    nome_razao: "Banco Bradesco",
                    emails: ["pessoa_um@bradesco.com.br", "pessoa_dois@bradesco.com.br"],
                    telefones: ["27999990000", "27999991111"],
                    cpf_cnpj:"32222888000146",
                    ativo: false,
                    ativo_desde: "01/02/2023",
                    inativo_desde: "28/02/2023",
                    contatos: [
                        {
                            nome: "Contato1",
                            email: "contato1@gmail.com",
                            telfone: "2733331111",
                            celular: "279999911111",
                        },
                        {
                            nome: "Contato2",
                            email: "contato2@gmail.com",
                            telfone: "2744441111",
                            celular: "278888811111",
                        },
                        {
                            nome: "Contato3",
                            email: "contato3@gmail.com",
                            telfone: "2744441111",
                            celular: "278888811111",
                        }
                    ],
                    endereco: {
                        logradouro: "Praça San Martin, 56",
                        complemento: "Loja 01",
                        bairro: "Praia do Canto",
                        cidade: "Vitoria",
                        estado: "Espirito Santo",
                        pais: "Brasil",
                        cep_zip: "29055170",
                        caixa_postal: "1111111"
                    }

                }
            ],
            productsList: [
                {
                    name: "Café",
                    codigoERP: "001",
                    codigoNCM: "SD51335",
                    id: "1"
                },
                {
                    name: "Pimenta",
                    codigoERP: "002",
                    codigoNCM: "SD11253",
                    id: "2"
                }
            ],
            usersList: [
                {
                    createdAt: "2023-02-22T07:41:57.456Z",
                    name: "Giuliano Lacerda",
                    email: "giuliano.lacerda@gmail.com",
                    status: "ativo",
                    papel: "admin",
                    id: "1"
                },
                {
                    createdAt: "2023-02-22T07:41:57.456Z",
                    name: "Joyce dos Reis Brito",
                    email: "joyce@gmail.com",
                    status: "ativo",
                    papel: "user",
                    id: "2"
                },
                {
                    createdAt: "2023-02-22T07:41:57.456Z",
                    name: "Pedro Tiago",
                    email: "pedro@gmail.com",
                    status: "inativo",
                    papel: "user",
                    id: "3"
                },
                {
                    createdAt: "2023-02-22T07:41:57.456Z",
                    name: "Maria Lacerda",
                    email: "maria@gmail.com",
                    status: "ativo",
                    papel: "admin",
                    id: "4"
                },
                {
                    createdAt: "2023-02-22T07:41:57.456Z",
                    name: "Marcelo",
                    email: "marcelo@gmail.com",
                    status: "inativo",
                    papel: "user",
                    id: "5"
                },
                {
                    createdAt: "2023-02-22T07:41:57.456Z",
                    name: "Marcelo",
                    email: "marcelo@gmail.com",
                    status: "inativo",
                    papel: "user",
                    id: "6"
                },
                {
                    createdAt: "2023-02-22T07:41:57.456Z",
                    name: "Marcelo",
                    email: "marcelo@gmail.com",
                    status: "inativo",
                    papel: "user",
                    id: "7"
                },
                {
                    createdAt: "2023-02-22T07:41:57.456Z",
                    name: "Marcelo",
                    email: "marcelo@gmail.com",
                    status: "inativo",
                    papel: "user",
                    id: "8"
                }
            ]
        }
    )
});

export default rootReducer;