import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import { Add, Remove } from "@mui/icons-material";
import FormCard from "./formCard";

export default function FormEmails(props) {
    const {
        inputFields,
        running,
        title,
        emailField,
        setEmails
    } = props;
    const fontSize = "12px";

    const handleEmail = (target, idx, emailType) => {
        let emailsList = inputFields[emailType];
        emailsList[idx] = target;
        setEmails(emailsList);
    };

    return (
        <Grid item xs={12} sm={12}>
            <FormCard title={title}>
                <Grid item xs={12} sm={4} marginBottom={1}>
                <ButtonGroup disabled={running} style={{ height: "14px" }} variant="outlined" size="small" aria-label="small button group">
                    <Button
                        disableElevation
                        onClick={() => {
                            let emailsList = inputFields[emailField];
                            emailsList.push("");
                            setEmails(emailsList);
                        }}>
                        <Add style={{ fontSize: fontSize }} /></Button>
                    <Button
                        disableElevation
                        onClick={() => {
                            if (inputFields[emailField].length > 1) {
                                let emailsList = inputFields[emailField];
                                emailsList.pop();
                                setEmails(emailsList);
                            }
                        }}>
                        <Remove style={{ fontSize: fontSize }} /></Button>
                </ButtonGroup>
                </Grid>
                <Grid container spacing={1}>
                    {inputFields[emailField] && inputFields[emailField].map((field, idx) => {
                        return (
                            <React.Fragment key={idx}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        required
                                        size="small"
                                        disabled={running}
                                        id={`"email_${emailField}_"${idx}`}
                                        name={`"email_${emailField}"`}
                                        label="Email"
                                        value={field}
                                        onChange={event => handleEmail(event.target.value, idx, emailField)}
                                        InputProps={{style: { fontSize: fontSize }}}
                                        InputLabelProps={{ style: { fontSize: fontSize } }}
                                        inputProps={{
                                            maxLength: 50
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </React.Fragment>
                        )
                    })}
                </Grid>
            </FormCard>
        </Grid>
    );
}