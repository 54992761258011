export const TOKEN_KEY = "@user-token";

export const decodeToken = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload)
}

export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);

export const setToken = (token) => {
  sessionStorage.setItem(TOKEN_KEY, token);
};

export const delToken = () => {
    sessionStorage.removeItem(TOKEN_KEY);
};

export const getUser = () => {
    const token = getToken();
    if (token) {
       return decodeToken(token)['sub'];
    }
    return null;
};

export const isUser = () => {
    const token = getToken();
    if (token) {
        if (decodeToken(token)['role'][0]['authority'] === 'ROLE_USUARIO') return true;
    }
    return false
}

export const isAdmin = () => {
    const token = getToken();
    if (token) {
        if (decodeToken(token)['role'] === 'ADMIN') return true;
    }
    return false
}

export const isBasic = () => {
    const token = getToken();
    if (token) {
        if (decodeToken(token)['role'] === 'BASIC') return true;
    }    
    return false
}

export const isManager = () => {
    const token = getToken();
    if (token) {
        if (decodeToken(token)['role'] === 'GERENTE') return true;
    }
    return false
}

export const getUserId = () => {
    const token = getToken();
    if (token) {
        return decodeToken(token)['id'];
    }
    return null;
}

export const checkRole = (array) => {
    const token = getToken();
    if (token) {
        const role = decodeToken(token)['role'];
        return array.includes(role);
    }
    return false;
}