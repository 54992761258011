import { useQuery, useQueries } from "react-query";
import { axiosClient } from "../query/axiosClient";
import { ACCS } from "../configs/endpoints";

const fetchAccById = async (id) => {
    const url = ACCS.ACCS_ONE(id);
    const { data } = await axiosClient.get(url);
    return data;
}

export const useAccsByIds = (ids) => {
    return useQueries(ids.map((id) => {
        return {
            queryKey: [`acc-${id}`, id],
            queryFn: () => fetchAccById(id),
            enabled: !!id,
            cacheTime: 0,
        }
    }));
}

const fetchAccsOpen = async (pageSize, pageIndex) => {
    const url = ACCS.ACCS_OPEN_LIST(pageSize, pageIndex);
    const { data } = await axiosClient.get(url);
    return data;
}

export const useAccsOpen = (pageSize, pageIndex) => {
    return useQuery({
        queryKey: ["accsOpen", pageSize, pageIndex],
        queryFn: () => fetchAccsOpen(pageSize, pageIndex),
        cacheTime: 0,
    });
}