import React, { useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Paper,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Box,
  Fade
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import LinearProgress from '@mui/material/LinearProgress';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { api } from '../../services/api';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const productBase = {
  'nome': '',
  'codigoERP': '',
  'descricao': '',
  'unidadeMedida': '',
  'classificacaoNCM': ''
}

export default function ProductCreate() {
  const [running, setRunning] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [inputFields, setInputFields] = useState(productBase);

  const createNewProduct = async item => {
    const resp = api.postProduct(JSON.stringify(item));
    return resp;
  }

  const handleSubmit = async e => {
    setRunning(true);
    e.preventDefault();
    const resp = await createNewProduct(inputFields);
    if (resp) {
      setMessage("Produto cadastrado com sucesso!");
      setSeverity("success");
      setInputFields(productBase);
    } else {
      setMessage("Erro ao cadastrar novo produto!");
      setSeverity("error");
    }
    setOpen(true);
  }


  const handleClose = () => {
    setRunning(false);
    setOpen(false);
  };


  return (
    <Container
      component="form"
      maxWidth="xg"
      sx={{ mt: 4, mb: 4 }}
      onSubmit={handleSubmit}>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}>
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
      <Paper variant="outlined" sx={{ width: '100%', mb: 1, borderRadius: '10px' }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            ml: 2,
            mr: 2,
            mb: 0
          }}
        >
          <Typography
            sx={{ m: 2, fontSize: '14px' }}
          >
            Novo Produto
          </Typography>
        </Box>
      </Paper>
      <Grid item xs={12}>
        <Fade
          in={running}
          style={{
            transitionDelay: running ? '100ms' : '0ms',
          }}
          unmountOnExit
        >
          <LinearProgress />
        </Fade>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 }, borderRadius: '10px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
          <Accordion expanded={true} style={{ borderRadius: '10px' }}>
            <AccordionSummary sx={{ height: "40px" }} expandIcon={false} style={{ minHeight: "0px", borderRadius: '10px', backgroundColor: "#fff" }} id="panel-tel-header">
              <Typography sx={{ fontSize: "12px" }}>Informações Gerais</Typography>

            </AccordionSummary>
            <AccordionDetails style={{ borderColor: "#fff" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size='small'
                    disabled={running}
                    required
                    id="fullName"
                    name="fullName"
                    label="Nome"
                    value={inputFields.nome}
                    onChange={event => setInputFields({ ...inputFields, nome: event.target.value })}
                    fullWidth
                    InputProps={{ style: { fontSize: "12px" } }}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    size='small'
                    disabled={running}
                    id="erpCode"
                    name="erpCode"
                    label="Codígo ERP"
                    value={inputFields.codigoERP}
                    onChange={event => setInputFields({ ...inputFields, codigoERP: event.target.value })}
                    fullWidth
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{
                      style: { fontSize: "12px" }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    size='small'
                    disabled={running}
                    id="ncm-code"
                    name="ncm-code"
                    label="Código NCM"
                    value={inputFields.classificacaoNCM}
                    onChange={event => setInputFields({ ...inputFields, classificacaoNCM: event.target.value })}
                    fullWidth
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{
                      style: { fontSize: "12px" }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size='small'
                    disabled={running}
                    id="description"
                    name="description"
                    label="Descrição"
                    value={inputFields.descricao}
                    onChange={event => setInputFields({ ...inputFields, descricao: event.target.value })}
                    fullWidth
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    InputProps={{
                      style: { fontSize: "12px" }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="input-unit">Unidade</InputLabel>
                    <Select
                      size='small'
                      disabled={running}
                      required
                      labelId="label-unit"
                      id="select-unit"
                      label="Unidade"
                      value={inputFields.unidadeMedida}
                      onChange={event => setInputFields({ ...inputFields, unidadeMedida: event.target.value })}
                      InputLabelProps={{ style: { fontSize: "12px" } }}
                      InputProps={{
                        style: { fontSize: "12px" }
                      }}
                    >
                      <MenuItem value={"Caixa"}>Caixa</MenuItem>
                      <MenuItem value={"Quilo"}>Quilo</MenuItem>
                      <MenuItem value={"Saco"}>Saco</MenuItem>
                      <MenuItem value={"Tonelada"}>Tonelada</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          </Grid>
          <Grid item xs={12} sm={12}>
          <Grid container>
            <LoadingButton
              style={{ textTransform: 'none', fontSize: '10px' }}
              loading={running}
              type="submit"
              startIcon={(<SaveIcon style={{ fontSize: "10px" }} />)}
              color="primary"
              variant="contained"
              size="small"
              disableElevation
            >
              Salvar
            </LoadingButton>
          </Grid>
        </Grid>
        </Grid>
        </Paper>
      </Grid>
    </Container>
  );
}