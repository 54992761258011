
const { REACT_APP_API_URL } = process.env;

export const PRERECEIPT = {
    PRERECEIPT_CREATE: `${REACT_APP_API_URL}prerecebimento`,
    PRERECEIPT_LIST: (pageSize = 10000000, pageIndex = 0) => `${REACT_APP_API_URL}prerecebimento?pageSize=${pageSize}&pageNo=${pageIndex}`,
    PRERECEIPT_ONE: (id) => `${REACT_APP_API_URL}prerecebimento/${id}`,
    PRERECEIPT_FILE: (id, file) => `${REACT_APP_API_URL}prerecebimento/${id}/${file}`,
    PRERECEIPT_DELETE: (id) => `${REACT_APP_API_URL}prerecebimento/${id}`,
}

export const SALES = {
    SALE_ONE: (id) => `${REACT_APP_API_URL}venda/${id}`,
    SALES_LIST: (pageSize = 10000000, pageIndex = 0) => `${REACT_APP_API_URL}venda?pageSize=${pageSize}&pageNo=${pageIndex}`,
    SALES_OPEN_LIST: (pageSize = 10000000, pageIndex = 0) => `${REACT_APP_API_URL}venda/open?pageSize=${pageSize}&pageNo=${pageIndex}`,
}

export const ACCS = {
    ACCS_ONE: (id) => `${REACT_APP_API_URL}acc/${id}`,
    ACCS_OPEN_LIST: (pageSize = 10000000, pageIndex = 0) => `${REACT_APP_API_URL}acc/open?pageSize=${pageSize}&pageNo=${pageIndex}`
}

export const RECEIVINGS = {
    RECEIVINGS_LIST: (pageSize = 10000000, pageIndex = 0) => `${REACT_APP_API_URL}recebimento?pageSize=${pageSize}&pageNo=${pageIndex}`,
    RECEIVINGS_ONE: (id) => `${REACT_APP_API_URL}recebimento/${id}`,
    RECEIVINGS_FILE: (id, file) => `${REACT_APP_API_URL}recebimento/${id}/${file}`,
    RECEIVINGS_DELETE: (id) => `${REACT_APP_API_URL}recebimento/${id}`,
}