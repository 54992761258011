import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isAdmin, isManager } from '../../services/auth';

import PageHeaderButton from '../../components/pageHeaderButton';
import ProductTable from "./productTable";
import { api } from '../../services/api';

const Product = props => {
    const [productList, setProductList] = useState([]);

    useEffect(() => {
        let mounted = true;
        api.getProducts()
            .then(resp => {
                if (mounted && resp) {
                    setProductList(resp)
                }
            })
        return () => mounted = false;
    }, [])

    return (
        <Container maxWidth="xg" sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={12}>
            <PageHeaderButton canCreate={isAdmin() || isManager()} title="Produtos" path="/products" ></PageHeaderButton>
            </Grid>
            <Grid item xs={12}>
                <ProductTable rows={productList}/>
            </Grid>
        </Container>
    );

};

export default Product;