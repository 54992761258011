import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid,
    Typography,
    Paper,
    Button,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Box,
    IconButton,
    InputAdornment,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { red } from '@mui/material/colors'
import { Add, Remove } from '@mui/icons-material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { styled } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { LoadingButton } from '@mui/lab';

import {
    MoneyTextMask,
    CodeTextMask
} from '../../components/masks';

import { api } from '../../services/api';

import { getUserId } from '../../services/auth';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const createNewSale = async item => {
    const formData = new FormData();
    for (let i = 0; i < item.arquivos.length; i++) {
        if (item.arquivos[i]["file"] != "" && item.arquivos[i]["field"] != "") {
            formData.append(item.arquivos[i]["field"], item.arquivos[i]["file"]);
        }
    }
    const newItem = { ...item };
    newItem.totalDolar = realToFloat(newItem.totalDolar);
    newItem.totalReais = realToFloat(newItem.totalReais);
    newItem.taxaCambial = realToFloat(newItem.taxaCambial);
    newItem.entrada = dolarToFloat(newItem.entrada);
    newItem.usuario = getUserId();
    const jsonBody = JSON.stringify(newItem);
    const blob = new Blob([jsonBody], {
        type: 'application/json'
    });
    formData.append("body", blob);
    const resp = await api.postSale(formData);
    return resp;
}

function dolarToFloat(value) {
    let newValue = value.replaceAll(",", "");
    return parseFloat(newValue);
}

function realToFloat(value) {
    let newValue = value.replace(/\./g, "");
    newValue = newValue.replace(",", ".");
    return parseFloat(newValue);
}

export default function FinInstitutionCreate() {
    const base = {
        "totalReais": "0",
        "totalDolar": "",
        "taxaCambial": "0",
        "entrada": "0",
        "produto": "",
        "importadora": "",
        "exportadora": "",
        "code": "",
        "dataVenda": null,
        "arquivos": []
    }

    const [inputFields, setInputFields] = useState(base);

    const [running, setRunning] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const [exporterList, setExporterList] = useState([]);
    const [importerList, setImporterList] = useState([]);
    const [productList, setProductList] = useState([]);

    useEffect(() => {
        let mounted = true;
        api.getExporters()
            .then(resp => {
                if (mounted && resp) {
                    if (resp.length == 1) {
                        setInputFields({ ...inputFields, exportadora: resp[0].id })
                    }
                    setExporterList(resp)
                }
            })
        api.getImporters()
            .then(resp => {
                if (mounted && resp) {
                    setImporterList(resp)
                }
            })
        api.getProducts()
            .then(resp => {
                if (mounted && resp) {
                    setProductList(resp)
                }
            })
        return () => mounted = false;
    }, [])

    const handleSubmit = async e => {
        setRunning(true);
        e.preventDefault();
        const resp = await createNewSale(inputFields);
        if (resp) {
            setMessage("Venda cadastrada com sucesso!");
            setSeverity("success");
        } else {
            setMessage("Erro ao cadastrar nova venda!");
            setSeverity("error");
        }
        setInputFields(base);
        setOpen(true)
    }

    const handleClose = (event, reason) => {
        setRunning(false);
        setOpen(false);
    };

    const handleAddFile = (target, idx) => {
        let filesList = inputFields.arquivos;
        filesList[idx]["file"] = target.files[0];
        setInputFields((inputFields) => ({
            ...inputFields, arquivos: filesList
        }));
    };

    const handleAddName = (target, idx) => {
        let filesList = inputFields.arquivos;
        filesList[idx]["field"] = target;
        setInputFields((inputFields) => ({
            ...inputFields, arquivos: filesList
        }));
    };

    return (
        <Container component="form"
            maxWidth="xg"
            sx={{ mt: 4, mb: 4 }}
            onSubmit={handleSubmit}>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>
            <Paper variant="outlined" sx={{ width: '100%', mb: 1 }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        ml: 2,
                        mr: 2,
                        mb: 0
                    }}
                >
                    <Typography
                        sx={{ m: 2 }}
                        variant="h5"
                    >
                        Nova venda
                    </Typography>
                </Box>
            </Paper>
            <Grid item xs={12}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 } }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Accordion expanded={true}>
                                <AccordionSummary expandIcon={false} id="panel-tel-header">
                                    <Typography>Informações da Venda</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                required
                                                id="code"
                                                name="code"
                                                label="Código"
                                                fullWidth
                                                onChange={event => setInputFields({ ...inputFields, code: event.target.value })}
                                                value={inputFields.code}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">KAV</InputAdornment>,
                                                    inputComponent: CodeTextMask
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormControl fullWidth>
                                                <InputLabel id="select-label">Exportador</InputLabel>
                                                <Select
                                                    required
                                                    id="exportadora"
                                                    value={inputFields.exportadora}
                                                    label="Exportadora"
                                                    // InputProps={{ readOnly: running }}
                                                    onChange={event => setInputFields({ ...inputFields, exportadora: event.target.value })}
                                                    fullWidth
                                                >
                                                    {exporterList.map((field, idx) => {
                                                        return (
                                                            <MenuItem key={field.id} value={field.id}>{field.razaoSocial}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormControl fullWidth>
                                                <InputLabel id="select-label">Cliente</InputLabel>
                                                <Select
                                                    required
                                                    id="importadora"
                                                    value={inputFields.importadora}
                                                    label="Cliente"
                                                    // InputProps={{ readOnly: running }}
                                                    onChange={event => setInputFields({ ...inputFields, importadora: event.target.value })}
                                                    fullWidth
                                                >
                                                    {importerList.map((field, idx) => {
                                                        return (
                                                            <MenuItem key={field.id} value={field.id}>{field.razaoSocial}</MenuItem>
                                                        )
                                                    })}

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <FormControl fullWidth>
                                                <InputLabel id="select-label">Produto</InputLabel>
                                                <Select
                                                    required
                                                    id="product"
                                                    value={inputFields.produto}
                                                    label="Produto"
                                                    // InputProps={{ readOnly: running }}
                                                    onChange={event => setInputFields({ ...inputFields, produto: event.target.value })}
                                                    fullWidth
                                                >
                                                    {productList.map((field, idx) => {
                                                        return (
                                                            <MenuItem key={field.id} value={field.id}>{field.nome}</MenuItem>
                                                        )
                                                    })}

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                required
                                                id="totalDolar"
                                                name="totalDolar"
                                                label="Total da Venda (Dólar)"
                                                fullWidth
                                                onChange={event => setInputFields({ ...inputFields, totalDolar: event.target.value })}
                                                value={inputFields.totalDolar}
                                                InputProps={{
                                                    readOnly: running,
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    inputComponent: MoneyTextMask
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                                <DatePicker
                                                    required
                                                    sx={{ width: '100%' }}
                                                    id="dataVenda"
                                                    name="dataVenda"
                                                    label="Data da venda"
                                                    format="DD/MM/YYYY"
                                                    value={inputFields.dataVenda}
                                                    onChange={event => setInputFields({ ...inputFields, dataVenda: event })}
                                                />

                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Accordion expanded={true}>
                                <AccordionSummary expandIcon={false} id="panel-tel-header">
                                    <Typography>Envio dos Arquivos</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <IconButton
                                                color="primary"
                                                aria-label="add file"
                                                size="small"
                                                onClick={() => {
                                                    let filesList = inputFields.arquivos;
                                                    filesList.push({ "field": "", "file": "" });
                                                    setInputFields({ ...inputFields, arquivos: filesList });
                                                }}
                                            >
                                                <Add />
                                            </IconButton>
                                            <IconButton
                                                sx={{ color: red[700] }}
                                                aria-label="rm file"
                                                size="small"
                                                onClick={() => {
                                                    if (inputFields.arquivos.length > 1) {
                                                        let filesList = inputFields.arquivos;
                                                        filesList.pop();
                                                        setInputFields({ ...inputFields, arquivos: filesList });
                                                    }
                                                }}
                                            >
                                                <Remove />
                                            </IconButton>
                                        </Grid>
                                        {inputFields.arquivos.map((field, idx) => {
                                            return (
                                                <React.Fragment key={idx}>
                                                    <Grid item xs={12} sm={3}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            disabled={running}
                                                            id={`${idx + 1}`}
                                                            name="filename"
                                                            label={`Arquivo ${idx + 1}`}
                                                            value={inputFields.arquivos[idx]["field"]}
                                                            onChange={event => handleAddName(event.target.value, idx)}
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            disabled={running}
                                                            id={`${idx + 1}`}
                                                            name="arquivo"
                                                            label={`Arquivo ${idx + 1}`}
                                                            value={inputFields.arquivos[idx]["file"].name}
                                                            InputLabelProps={{ shrink: true }}
                                                            InputProps={{
                                                                readOnly: true,
                                                                endAdornment:
                                                                    <Button
                                                                        size="small"
                                                                        component="label"
                                                                        color="primary"
                                                                        variant="outlined">
                                                                        <input
                                                                            hidden
                                                                            accept="pdf/*"
                                                                            multiple
                                                                            type="file"
                                                                            onChange={event => handleAddFile(event.target, idx)}
                                                                        >
                                                                        </input>
                                                                        <UploadFileIcon />
                                                                    </Button>
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}></Grid>
                                                </React.Fragment>
                                            )
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <LoadingButton
                                    style={{textTransform: 'none'}}
                                    loading={running}
                                    type="submit"
                                    startIcon={(<SaveIcon style={{fontSize: "12px"}}/>)}
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    disableElevation
                                    sx={{ width: "200px" }}
                                >
                                    Salvar
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Container>
    );
}
