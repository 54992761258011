import React, { useState } from "react";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { api } from '../../services/api';

// import Title from './Title';

function preventDefault(event) {
    event.preventDefault();
}


export default function ReceiptCard(props) {
    const message = props.message;
    const values = props.values;
    const arquivos = props.arquivos;
    const id = props.id;
    const [currentDate, setCurrentDate] = useState(dayjs(new Date()).format('DD/MM/YYYY - hh:mm:ss').toString());

    const downloadFile = async (key) => {
        const resp = await api.getFileReceivings(id, key);
        const link = document.createElement('a');
        link.href = resp;
        link.setAttribute(
            'download',
            `FileName.pdf`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    return (
        <Box sx={{ minWidth: 200 }}>
            <Card variant="outlined" sx={{ borderRadius: '7px', paddingX: '7px' }}>
                <CardContent>
                    <Grid container alignItems="center">
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: '10px' }} color="primary" gutterBottom>
                                {message}
                            </Typography>
                            <Typography style={{ fontSize: '18px' }}>
                                {values}
                            </Typography>
                            <Grid item xs={12} paddingTop={2}>
                                <Typography color="text.secondary" style={{ fontSize: '8px' }}>
                                    {currentDate}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1} paddingTop={1}>
                                <List dense sx={{ width: '100%', overflow: 'auto', bgcolor: '#fff', display: 'flex', flexDirection: 'row', padding: 0 }}>
                                    {arquivos && Object.entries(arquivos).map(([key, value]) => (

                                        <Chip
                                            style={{ fontSize: '8px' }}
                                            label={key}
                                            onClick={() => downloadFile(key)}
                                            onDelete={() => downloadFile(key)}
                                            sx={{ height: '15px' }}
                                            deleteIcon={<FileDownloadIcon style={{ fontSize: '10px' }} />}
                                        />

                                    ))}
                                </List>
                            </Stack>
                        </Grid>
                        <Grid item xs={2}>
                            {props.children}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
}