import React, { useState } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';

export default function SaleCard(props) {
    const {client, message, produto, total, closed, open} = props;
    const [currentDate, setCurrentDate] = useState(dayjs(new Date()).format('DD/MM/YYYY - hh:mm:ss').toString());
    return (
        <Box sx={{ minWidth: 200 }}>
            <Card variant="outlined" sx={{ borderRadius: '7px', paddingX: '7px' }}>
                <CardContent>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography style={{ fontSize: '10px' }} color="primary" gutterBottom>
                                {message}
                            </Typography>

                            <Grid item xs={12} paddingTop={1}>
                                <Typography style={{ fontSize: '10px' }}>
                                    {client}
                                </Typography>
                            </Grid>

                            <Typography style={{ fontSize: '16px' }}>
                                {total}
                            </Typography>
                            <Typography color="primary" style={{ fontSize: '10px' }}>
                                {closed}
                            </Typography>
                            <Typography color="secondary" style={{ fontSize: '10px' }}>
                                {open}
                            </Typography>

                            <Grid item xs={12} paddingTop={2}>
                                <Typography style={{ fontSize: '9px' }}>
                                    {produto}
                                </Typography>
                            </Grid>
                            
                            <Grid item xs={12} paddingTop={2}>
                                <Typography color="text.secondary" style={{ fontSize: '9px' }}>
                                    {currentDate}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            {props.children}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
}