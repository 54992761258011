import { Add, Remove } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { formatFloatToReal, sumReceivedValues, sumPreReceivedValues } from '../../common/utils';
import { isAdmin, isManager } from '../../services/auth';
import { useNavigate, useParams } from 'react-router-dom';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CancelIcon from '@mui/icons-material/Cancel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Delete from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Edit from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FormCard from '../../components/forms/formCard';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  MoneyTextMask
} from '../../components/masks';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAlert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { api } from '../../services/api';
import dayjs from 'dayjs';
import { red } from '@mui/material/colors'
import { styled } from '@mui/material/styles';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const SaleView = (props) => {
  const { id } = useParams();
  const [field, setField] = useState({ "arquivos": [], "arquivos_list": [] });
  const [fieldBack, setFieldBack] = useState({ "arquivos_list": [] });
  const navigate = useNavigate();

  const [editing, setEditing] = useState(false);
  const [running, setRunning] = useState(true);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [canDelete, setCanDelete] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const [importerList, setImporterList] = useState([]);
  const [productList, setProductList] = useState([]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const deleteSale = async id => {
    const resp = api.delSale(id);
    return resp;
  }

  const handleCloseDialog = async (needRemove) => {
    if (needRemove) {
      setRunning(true);
      setOpenDialog(false);
      const resp = await deleteSale(id);
      if (resp) {
        setMessage("Recebimento excluído com sucesso!");
        setSeverity("success");
        navigate("/sales");
      } else {
        setMessage("Erro ao excluir Recebimento!");
        setSeverity("error");
        setRunning(false);
      }
    } else {
      setOpenDialog(false);
      setRunning(false);
    }
  };

  const handleAddFile = (target, idx) => {
    let filesList = field.arquivos_list;
    filesList[idx]["file"] = target.files[0];
    setField((field) => ({
      ...field, arquivos_list: filesList
    }));
  };

  const handleAddName = (target, idx) => {
    let filesList = field.arquivos_list;
    filesList[idx]["field"] = target;
    setField((field) => ({
      ...field, arquivos_list: filesList
    }));
  };

  const updateSale = async (item, itemBack) => {
    const formData = new FormData();
    for (let i = 0; i < item.arquivos_list.length; i++) {
      if (item.arquivos_list[i]["file"] != "" && item.arquivos_list[i]["field"] != "") {
        formData.append(item.arquivos_list[i]["field"], item.arquivos_list[i]["file"]);
      }
    }
    // delete item.arquivos_list;
    delete item.recebimentos;
    const newItem = { ...item };
    if (typeof newItem.importadora !== "string") {
      newItem.importadora = newItem.importadora.id;
    }
    if (typeof newItem.produto !== "string") {
      newItem.produto = newItem.produto.id;
    }
    delete newItem.usuario;
    delete newItem.contrato;
    delete newItem.nota;

    newItem.exportadora = newItem.exportadora.id;
    newItem.totalDolar = newItem.totalDolar !== "" ? realToFloat(newItem.totalDolar) : itemBack.totalDolar;
    newItem.totalReais = newItem.totalReais !== "" ? realToFloat(newItem.totalReais) : itemBack.totalReais;
    newItem.taxaCambial = newItem.taxaCambial !== "" ? realToFloat(newItem.taxaCambial) : itemBack.taxaCambial;

    const jsonBody = JSON.stringify(newItem);
    const blob = new Blob([jsonBody], {
      type: 'application/json'
    });
    formData.append("body", blob);

    const resp = api.putSale(formData, item.id);
    return resp;
  }

  function dolarToFloat(value) {
    let newValue = value.replaceAll(",", "");
    return parseFloat(newValue);
  }

  function realToFloat(value) {
    let newValue = value.replace(/\./g, "");
    newValue = newValue.replace(",", ".");
    return parseFloat(newValue);
  }

  const downloadFile = async (key) => {
    const resp = await api.getFileSale(field.id, key);
    const link = document.createElement('a');
    link.href = resp;
    link.setAttribute(
      'download',
      `FileName.pdf`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  const handleSubmit = async e => {
    setRunning(true);
    e.preventDefault();
    const resp = await updateSale(field, fieldBack);
    if (resp) {
      setMessage("Atualizado com sucesso!");
      setSeverity("success");
      setField({
        ...resp, arquivos_list: []
      });
      setFieldBack({
        ...resp, arquivos_list: []
      });
    } else {
      setField(fieldBack);
      setMessage("Erro ao atualizar!");
      setSeverity("error");
    }
    setRunning(false);
    setEditing(false);
    setOpen(true);
  }

  const handleClose = () => {
    setRunning(false);
    setOpen(false);
  };

  const handleEdit = () => {
    setField({
      ...field, totalReais: "0", taxaCambial: "0", totalDolar: ""
    })
    setEditing(true);
  };

  const cancelSubmit = () => {
    setField(fieldBack);
    setEditing(false);
  }

  useEffect(() => {
    let mounted = true;
    api.getOneSale(id)
      .then(resp => {
        if (mounted && resp) {
          setField({
            ...field, ...resp
          });
          setFieldBack({
            ...field, ...resp
          });
          setRunning(false);

          if (resp.recebimentos.length === 0 && isAdmin()) {
            setCanDelete(true);
          }

          if (isAdmin() || isManager()) {
            setCanEdit(true);
          }
        }
      })
    api.getImporters()
      .then(resp => {
        if (mounted && resp) {
          setImporterList(resp)
        }
      })
    api.getProducts()
      .then(resp => {
        if (mounted && resp) {
          setProductList(resp)
        }
      })
    return () => mounted = false;
  }, [])

  return (
    <Container component="form"
      maxWidth="xg"
      sx={{ mt: 4, mb: 4 }}
      onSubmit={handleSubmit}>

      <Dialog
        open={openDialog}
        onClose={() => handleCloseDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Exclusão de Venda</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Certeza que deseja excluir a venda KAV-{field.code}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleCloseDialog(true)} color="error" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}>
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
      {!editing && <Paper variant="outlined" sx={{ p: { xs: 2, md: 2 }, borderRadius: '10px' }}>

        <Grid item xs={12} sm={12}>

        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            ml: 1,
            mr: 1,
            mb: 0
          }}
        >
          <Typography
            sx={{ fontSize: "18px" }}
          >
            Detalhes da Venda
          </Typography>
          <div>
            {canEdit && !editing &&
              <Button
                style={{ textTransform: 'none', fontSize: '10px' }}
                startIcon={(<Edit style={{ fontSize: "10px" }} />)}
                size="small"
                variant="contained"
                disabled={running}
                disableElevation
                onClick={() => handleEdit(id)}
              >
                Editar
              </Button>}
            {canDelete && !editing && <Button
              style={{ textTransform: 'none', fontSize: '10px', marginLeft: "10px" }}
              startIcon={(<Delete style={{ fontSize: "10px" }} />)}
              color="error"
              variant="outlined"
              size="small"
              disabled={running}
              disableElevation
              onClick={() => handleOpenDialog()}
            >
              Excluir
            </Button>}
          </div>
        </Box>

        <FormCard title="Venda">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                size='small'
                InputProps={{
                  readOnly: true,
                  style: { fontSize: "12px" },
                  sx: { backgroundColor: '#fafafa' }
                }}
                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                inputProps={{
                  maxLength: 60,
                }}
                id="saleCode"
                name="saleCode"
                label="Código da Venda"
                value={"KAV-" + field.code || ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size='small'
                InputProps={{
                  readOnly: true,
                  style: { fontSize: "12px" },
                  sx: { backgroundColor: '#fafafa' }
                }}
                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                inputProps={{
                  maxLength: 60,
                }}
                id="client"
                name="client"
                label="Cliente"
                value={field.importadora && field.importadora.razaoSocial || ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                size='small'
                InputProps={{
                  readOnly: true,
                  style: { fontSize: "12px" },
                  sx: { backgroundColor: '#fafafa' }
                }}
                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                inputProps={{
                  maxLength: 60,
                }}
                id="dataVenda"
                name="dataVenda"
                label="Data da Venda"
                value={dayjs(field.dataVenda).format('DD/MM/YYYY').toString() || ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                size='small'
                InputProps={{
                  readOnly: true,
                  style: { fontSize: "12px" },
                  sx: { backgroundColor: '#fafafa' }
                }}
                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                inputProps={{
                  maxLength: 60,
                }}
                id="dataCriacao"
                name="dataCriacao"
                label="Data de Lançamento"
                value={dayjs(field.creationDate).format('DD/MM/YYYY').toString() || ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size='small'
                InputProps={{
                  readOnly: true,
                  style: { fontSize: "12px" },
                  sx: { backgroundColor: '#fafafa' }
                }}
                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                inputProps={{
                  maxLength: 60,
                }}
                id="produtoCode"
                name="produtoCode"
                label="Produto Código ERP"
                value={field.produto?.codigoERP ?? ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size='small'
                InputProps={{
                  readOnly: true,
                  style: { fontSize: "12px" },
                  sx: { backgroundColor: '#fafafa' }
                }}
                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                inputProps={{
                  maxLength: 60,
                }}
                id="produtoNome"
                name="produtoNome"
                label="Produto"
                value={field.produto?.nome ?? ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size='small'
                InputProps={{
                  readOnly: true,
                  style: { fontSize: "12px" },
                  sx: { backgroundColor: '#fafafa' }
                }}
                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                inputProps={{
                  maxLength: 60,
                }}
                id="produtoDescricao"
                name="produtoDescricao"
                label="Descrição do Produto"
                value={field.produto?.descricao ?? ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                size='small'
                InputProps={{
                  readOnly: true,
                  style: { fontSize: "12px" },
                  sx: { backgroundColor: '#fafafa' }
                }}
                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                inputProps={{
                  maxLength: 60,
                }}
                id="totalVenda"
                name="totalVenda"
                label="Total da Venda"
                value={"$ " + formatFloatToReal(field.totalDolar, 2) ?? ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                size='small'
                InputProps={{
                  readOnly: true,
                  style: { fontSize: "12px" },
                  sx: { backgroundColor: '#fafafa' }
                }}
                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                inputProps={{
                  maxLength: 60,
                }}
                id="totalRecebido"
                name="totalRecebido"
                label="Total de Recebimentos"
                value={"$ " + formatFloatToReal(sumReceivedValues(field.recebimentos), 2) ?? ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                size='small'
                InputProps={{
                  readOnly: true,
                  style: { fontSize: "12px" },
                  sx: { backgroundColor: '#fafafa' }
                }}
                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                inputProps={{
                  maxLength: 60,
                }}
                id="totalPreRecebido"
                name="totalPreRecebido"
                label="Total em Pré-Recebimentos"
                value={"$ " + formatFloatToReal(sumPreReceivedValues(field.preRecebimento), 2) ?? ""}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                size='small'
                InputProps={{
                  readOnly: true,
                  style: { fontSize: "12px" },
                  sx: { backgroundColor: '#fafafa' }
                }}
                InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                inputProps={{
                  maxLength: 60,
                }}
                id="totalAberto"
                name="totalAberto"
                label="Total em Aberto"
                value={"$ " + formatFloatToReal(field.totalDolar - (sumReceivedValues(field.recebimentos)), 2) ?? ""}
                fullWidth
              />
            </Grid>
          </Grid>
        </FormCard>
        <FormCard title="Arquivos do Recebimento">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <List sx={{ width: '100%', maxWidth: 400, bgcolor: '#fff', display: 'flex', flexDirection: 'row', padding: 0 }}>
                {field.arquivos && Object.entries(field.arquivos).map(([key, value]) => (
                  <ListItem key={key}>
                    <Button style={{ textTransform: 'none' }} variant="outlined" onClick={() => downloadFile(key)}
                      startIcon={<FileDownloadIcon />}
                    >
                      {key}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </FormCard>

      </Paper>}

      { /* Formulário de Edição */}
      {editing ?
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 } }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Accordion expanded={true}>
                  <AccordionSummary expandIcon={false} id="panel-tel-header">
                    <Typography>Informações da Venda</Typography>

                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          required
                          disabled={running}
                          id="code"
                          name="code"
                          label="Código"
                          fullWidth
                          onChange={event => setField({ ...field, code: event.target.value })}
                          value={field.code}
                          InputProps={{
                            sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#f7f7f7' },
                            startAdornment: <InputAdornment position="start">KAV</InputAdornment>,
                            readOnly: !editing
                            // inputComponent: CodeTextMask
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          required
                          disabled={running}
                          id="exportadora"
                          name="exportadora"
                          label="Exportadora"
                          fullWidth
                          // onChange={event => setField({ ...field, exportadora: event.target.value })}
                          value={field.exportadora ? field.exportadora.razaoSocial : ""}
                          InputProps={{
                            sx: { backgroundColor: '#f7f7f7' },
                            readOnly: true
                            // inputComponent: CodeTextMask
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      {editing &&
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <InputLabel id="select-label">Cliente</InputLabel>
                            <Select
                              required
                              id="importadora"
                              label="Cliente"
                              defaultValue={field.importadora.id}
                              onChange={event => setField({ ...field, importadora: event.target.value })}

                              fullWidth
                            >
                              {importerList.map((field, idx) => {
                                return (
                                  <MenuItem key={field.id} value={field.id}>{field.razaoSocial}</MenuItem>
                                )
                              })}

                            </Select>
                          </FormControl>
                        </Grid>}
                      {editing &&
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <InputLabel id="select-label">Produto</InputLabel>
                            <Select
                              required
                              id="product"
                              label="Produto"
                              defaultValue={field.produto.id}
                              onChange={event => setField({ ...field, produto: event.target.value })}
                              fullWidth
                            >
                              {productList.map((ff, idx) => {
                                return (
                                  <MenuItem key={ff.id} value={ff.id}>{ff.nome}</MenuItem>
                                )
                              })}

                            </Select>
                          </FormControl>
                        </Grid>}
                      {editing &&
                        <Grid item xs={12} sm={4}>
                          <TextField
                            disabled={running}
                            id="totalDolar"
                            name="totalDolar"
                            label="Total da Venda (Dólar)"
                            fullWidth
                            value={field.totalDolar}
                            helperText={editing ? "Valor atual: " + fieldBack.totalDolar : ""}
                            onChange={event => setField({ ...field, totalDolar: event.target.value })}
                            InputProps={{
                              sx: { backgroundColor: '#fff' },
                              readOnly: false,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              inputComponent: MoneyTextMask
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>}
                      {editing &&
                        <Grid item xs={12} sm={4}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>

                            <DatePicker
                              size='small'
                              sx={{ width: '100%' }}
                              label="Data da venda"
                              id="dataVenda"
                              name="dataVenda"
                              defaultValue={dayjs(field.dataVenda).startOf('day')}
                              format="DD/MM/YYYY"
                              onChange={event => setField({
                                ...field, dataVenda: event
                              })}
                            />

                          </LocalizationProvider>
                        </Grid>}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Accordion expanded={true}>
                  <AccordionSummary expandIcon={false} id="panel-tel-header">
                    <Typography>Arquivos</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <List sx={{ width: '100%', maxWidth: 400, bgcolor: '#fff', display: 'flex', flexDirection: 'row', padding: 0 }}>
                          {Object.entries(field.arquivos).map(([key, value]) => (
                            <ListItem key={key}>
                              <Button style={{ textTransform: 'none' }} variant="outlined" onClick={() => downloadFile(key)}
                                startIcon={<FileDownloadIcon />}
                              >
                                {key}
                              </Button>
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                      {editing ? <Grid item xs={12} sm={12}>
                        <IconButton
                          color="primary"
                          aria-label="add file"
                          size="small"
                          onClick={() => {
                            let filesList = field.arquivos_list;
                            filesList.push({ "field": "", "file": "" });
                            setField({ ...field, arquivos_list: filesList });
                          }}
                        >
                          <Add />
                        </IconButton>
                        <IconButton
                          sx={{ color: red[700] }}
                          aria-label="rm file"
                          size="small"
                          onClick={() => {
                            if (field.arquivos_list.length > 1) {
                              let filesList = field.arquivos_list;
                              filesList.pop();
                              setField({ ...field, arquivos_list: filesList });
                            }
                          }}
                        >
                          <Remove />
                        </IconButton>
                      </Grid> : null}
                      {editing ?
                        field.arquivos_list.map((f, idx) => {
                          return (
                            <React.Fragment key={idx}>
                              <Grid item xs={12} sm={3}>
                                <TextField
                                  fullWidth
                                  size="small"
                                  disabled={running}
                                  id={`${idx + 1}`}
                                  name="filename"
                                  label={`Arquivo ${idx + 1}`}
                                  value={field.arquivos_list[idx]["field"]}
                                  onChange={event => handleAddName(event.target.value, idx)}
                                  // InputProps={{
                                  //     inputComponent: TelTextMask
                                  // }}
                                  // fullWidth
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <TextField
                                  fullWidth
                                  size="small"
                                  disabled={running}
                                  id={`${idx + 1}`}
                                  name="arquivo"
                                  label={`Arquivo ${idx + 1}`}
                                  value={field.arquivos_list[idx]["file"].name}
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment:
                                      <Button
                                        size="small"
                                        component="label"
                                        color="primary"
                                        variant="outlined">
                                        <input
                                          hidden
                                          accept="pdf/*"
                                          multiple
                                          type="file"
                                          onChange={event => handleAddFile(event.target, idx)}
                                        >
                                        </input>
                                        <UploadFileIcon />
                                      </Button>
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}></Grid>
                            </React.Fragment>
                          )
                        })
                        : null}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              {editing ?
                <Grid item >
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} sm={6}>
                      <LoadingButton
                        style={{ textTransform: 'none' }}
                        size='small'
                        loading={running}
                        type="submit"
                        startIcon={(<SaveIcon style={{ fontSize: "12px" }} />)}
                        color="primary"
                        variant="contained"
                        disableElevation
                        sx={{ width: "150px" }}
                      >
                        Salvar
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LoadingButton
                        style={{ textTransform: 'none' }}
                        size='small'
                        loading={running}
                        startIcon={(<CancelIcon style={{ fontSize: "12px" }} />)}
                        color="primary"
                        variant='outlined'
                        disableElevation
                        onClick={() => cancelSubmit()}
                        sx={{ width: "150px" }}
                      >
                        Cancelar
                      </LoadingButton>
                    </Grid>
                  </Grid>

                </Grid>
                : null}
            </Grid>
          </Paper>
        </Grid>
        : null}
    </Container>
  );
};

export default SaleView;