import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Grid,
    Typography,
    Paper,
    Box,
    Button,
    Fade
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import { Add, Edit, Delete, Remove } from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import {
    CnpjTextMask,
    TelTextMask,
    NumberTextMask,
    CepTextMask,
    CelTextMask,
    AlphaTextMask
} from '../../components/masks';

import { api } from '../../services/api';

import dayjs from 'dayjs';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const ExporterView = (props) => {
    const { id } = useParams();
    const [inputFields, setInputFields] = useState({
        "contatos": [],
        "emailsFinanceiros": [],
        "emailsContabeis": []
    });
    const [fieldsBack, setFieldsBack] = useState({});
    const navigate = useNavigate();

    const [editing, setEditing] = useState(false);
    const [running, setRunning] = useState(false)
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const handleAddAttr = (target, idx, field) => {
        let contatosList = inputFields.contatos;
        contatosList[idx][field] = target;
        setInputFields((inputFields) => ({
            ...inputFields, contatos: contatosList
        }));
    };

    const handleEmail = (target, idx, emailType) => {
        let emailsList = inputFields[emailType];
        emailsList[idx] = target;
        if (emailType == "emailsContabeis") {
            setInputFields((inputFields) => ({
                ...inputFields, emailsContabeis: emailsList
            }));
        } else {
            setInputFields((inputFields) => ({
                ...inputFields, emailsFinanceiros: emailsList
            }));
        }
    };

    const updateExporter = async (item, id) => {
        const resp = api.putExporter(JSON.stringify(item), id);
        return resp;
    }

    const deleteExporter = async id => {
        const resp = api.delExporter(id);
        return resp;
    }

    const handleClose = () => {
        setRunning(false);
        setOpen(false);
    };

    const handleDelete = async id => {
        setRunning(true);
        const resp = await deleteExporter(id);
        if (resp) {
            setRunning(false);
            navigate(-1);
        } else {
            setRunning(false);
            setMessage("Erro ao excluir empresa!");
            setSeverity("error");
        }
    }

    const handleEdit = () => {
        setEditing(true);
    }

    const handleSubmit = async e => {
        setRunning(true);
        e.preventDefault();
        const resp = await updateExporter(inputFields, id);
        if (resp) {
            setMessage("Empresa atualizada com sucesso!");
            setSeverity("success");
            setInputFields(resp);
            setFieldsBack(resp);
        } else {
            setMessage("Erro ao atualizar empresa!");
            setSeverity("error");
        }
        setOpen(true);
        setRunning(false);
        setEditing(false);
    }

    const cancelSubmit = () => {
        setInputFields(fieldsBack);
        setEditing(false);
    }

    useEffect(() => {
        let mounted = true;
        api.getOneExporter(id)
            .then(resp => {
                if (mounted) {
                    setInputFields({...resp,
                        contatos: resp.contatos ? resp.contatos : [],
                        emailsFinanceiros: resp.emailsFinanceiros ? resp.emailsFinanceiros : [],
                        emailsContabeis: resp.emailsContabeis ? resp.emailsContabeis : []
                    });
                    setFieldsBack({...resp,
                        contatos: resp.contatos ? resp.contatos : [],
                        emailsFinanceiros: resp.emailsFinanceiros ? resp.emailsFinanceiros : [],
                        emailsContabeis: resp.emailsContabeis ? resp.emailsContabeis : []
                    });
                }
            })
        return () => mounted = false;
    }, [])

    return (
        <Container
            maxWidth="xg"
            sx={{ mt: 4, mb: 4 }}
            component="form"
            onSubmit={handleSubmit}>
            <Grid item xs={12}>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar
                        open={open}
                        autoHideDuration={3000}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}>
                        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>
                </Stack>

                <Paper variant="outlined" sx={{ width: '100%', mb: 1 }}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            ml: 2,
                            mr: 2,
                            mb: 0
                        }}
                    >
                        <Typography
                            sx={{ m: 2 }}
                            variant="h5"
                        >
                            Informações da exportadora
                        </Typography>
                        {!editing ?
                            <Box sx={{ m: 1 }}>
                                <Button
                                    startIcon={(<Edit />)}
                                    sx={{ mr: 2 }}
                                    size="small"
                                    variant="contained"
                                    disabled={running}
                                    disableElevation
                                    onClick={() => handleEdit(id)}
                                >
                                    Editar
                                </Button>
                                {/* <Button
                                    startIcon={(<Delete />)}
                                    color="error"
                                    variant="outlined"
                                    size="small"
                                    disabled={running}
                                    disableElevation
                                    onClick={() => handleDelete(id)}
                                >
                                    Excluir
                                </Button> */}
                            </Box> : null}
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Fade
                    in={running}
                    style={{
                        transitionDelay: running ? '100ms' : '0ms',
                    }}
                    unmountOnExit
                >
                    <LinearProgress />
                </Fade>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 } }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Accordion expanded={true}>
                                <AccordionSummary expandIcon={false} id="panel-tel-header">
                                    <Typography>Informações Gerais</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                disabled={running}
                                                InputProps={{
                                                    readOnly: !editing,
                                                    inputComponent: AlphaTextMask
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                id="fullName"
                                                name="fullName"
                                                label="Nome"
                                                value={inputFields.razaoSocial}
                                                onChange={event => setInputFields({ ...inputFields, razaoSocial: event.target.value })}
                                                inputProps={{
                                                    maxLength: 60
                                                }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                disabled={running}
                                                InputProps={{
                                                    readOnly: !editing,
                                                    inputComponent: CnpjTextMask
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                id="cnpj"
                                                name="cnpj"
                                                label="CNPJ"
                                                value={inputFields.cnpj}
                                                onChange={event => setInputFields({ ...inputFields, cnpj: event.target.value })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                disabled={running}
                                                InputProps={{ readOnly: !editing }}
                                                InputLabelProps={{ shrink: true }}
                                                id="status"
                                                name="status"
                                                label={inputFields.inativoDesde ? "Inativo Desde" : "Ativo Desde"}
                                                value={inputFields.inativoDesde ? dayjs(inputFields.inativoDesde).format('DD/MM/YYYY').toString() : dayjs(inputFields.ativoDesde).format('DD/MM/YYYY').toString()}
                                                onChange={event => setInputFields({ ...inputFields, status: event.target.value })}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Accordion expanded={true}>
                                <AccordionSummary expandIcon={false} aria-controls="panel1d-content" id="panel-tel-header">
                                    <Typography>Endereço</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                disabled={running}
                                                InputProps={{ readOnly: !editing }}
                                                InputLabelProps={{ shrink: true }}
                                                id="logradouro"
                                                name="logradouro"
                                                label="Logradouro"
                                                value={inputFields.endereco && inputFields.endereco.logradouro}
                                                onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, logradouro: event.target.value } })}
                                                inputProps={{
                                                    maxLength: 30
                                                }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                disabled={running}
                                                InputProps={{
                                                    readOnly: !editing,
                                                    inputComponent: NumberTextMask
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                id="numero"
                                                name="numero"
                                                label="Número"
                                                value={inputFields.endereco && inputFields.endereco.numero}
                                                onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, numero: event.target.value } })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                disabled={running}
                                                InputProps={{ readOnly: !editing }}
                                                InputLabelProps={{ shrink: true }}
                                                id="bairro"
                                                name="bairro"
                                                label="Bairro"
                                                value={inputFields.endereco && inputFields.endereco.bairro}
                                                onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, bairro: event.target.value } })}
                                                inputProps={{
                                                    maxLength: 30
                                                }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                disabled={running}
                                                InputProps={{ readOnly: !editing }}
                                                InputLabelProps={{ shrink: true }}
                                                id="complemento"
                                                name="complemento"
                                                label="Complemento"
                                                value={inputFields.endereco && inputFields.endereco.complemento}
                                                onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, complemento: event.target.value } })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                disabled={running}
                                                InputProps={{ readOnly: !editing }}
                                                InputLabelProps={{ shrink: true }}
                                                id="cidade"
                                                name="cidade"
                                                label="Cidade"
                                                value={inputFields.endereco && inputFields.endereco.cidade}
                                                onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, cidade: event.target.value } })}
                                                inputProps={{
                                                    maxLength: 30
                                                }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                disabled={running}
                                                InputProps={{ readOnly: !editing }}
                                                InputLabelProps={{ shrink: true }}
                                                id="estado"
                                                name="estado"
                                                label="Estado"
                                                value={inputFields.endereco && inputFields.endereco.estado}
                                                onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, estado: event.target.value } })}
                                                inputProps={{
                                                    maxLength: 20
                                                }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                disabled={running}
                                                InputProps={{ readOnly: !editing }}
                                                InputLabelProps={{ shrink: true }}
                                                id="pais"
                                                name="pais"
                                                label="País"
                                                value={inputFields.endereco && inputFields.endereco.pais}
                                                onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, pais: event.target.value } })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                disabled={running}
                                                InputProps={{
                                                    readOnly: !editing,
                                                    inputComponent: CepTextMask
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                id="cep"
                                                name="cep"
                                                label="Cep"
                                                value={inputFields.endereco && inputFields.endereco.zipCode}
                                                onChange={event => setInputFields({ ...inputFields, endereco: { ...inputFields.endereco, zipCode: event.target.value } })}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Accordion expanded={true}>
                                <AccordionSummary expandIcon={false} aria-controls="panel1d-content" id="panel-tel-header">
                                    <Typography>Contatos</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    {editing && <Grid xs={12} sm={12} marginBottom={1}>
                                        <ButtonGroup disabled={running} style={{ height: "20px" }} variant="outlined" size="small" aria-label="small button group">
                                            <Button
                                                disableElevation
                                                onClick={() => {
                                                    let contatoList = inputFields.contatos;
                                                    contatoList.push({
                                                        "nome": "",
                                                        "email": "",
                                                        "celular": "",
                                                        "telefone": ""
                                                    });
                                                    setInputFields({ ...inputFields, contatos: contatoList });
                                                }}>
                                                <Add /></Button>
                                            <Button
                                                disableElevation
                                                onClick={() => {
                                                    if (inputFields.contatos.length > 1) {
                                                        let contatoList = inputFields.contatos;
                                                        contatoList.pop();
                                                        setInputFields({ ...inputFields, contatos: contatoList });
                                                    }
                                                }}>
                                                <Remove /></Button>
                                        </ButtonGroup>
                                    </Grid>}
                                    <Grid container spacing={3}>
                                        {inputFields.contatos.map((field, idx) => {
                                            return (
                                                <React.Fragment key={idx}>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            disabled={running}
                                                            InputProps={{ readOnly: !editing }}
                                                            InputLabelProps={{ shrink: true }}
                                                            size="small"
                                                            required
                                                            id={`"nome_"${idx}`}
                                                            name="nome"
                                                            label="Nome"
                                                            value={field["nome"]}
                                                            onChange={event => handleAddAttr(event.target.value, idx, "nome")}
                                                            inputProps={{
                                                                maxLength: 40
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            size="small"
                                                            disabled={running}
                                                            InputProps={{ readOnly: !editing }}
                                                            InputLabelProps={{ shrink: true }}
                                                            id={`"email_"${idx}`}
                                                            name="email"
                                                            label="Email"
                                                            value={field["email"]}
                                                            onChange={event => handleAddAttr(event.target.value, idx, "email")}
                                                            inputProps={{
                                                                maxLength: 50
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <TextField
                                                            size="small"
                                                            disabled={running}
                                                            InputLabelProps={{ shrink: true }}
                                                            id={`"telefone_"${idx}`}
                                                            name="telefone"
                                                            label="Telefone"
                                                            value={field["telefone"]}
                                                            onChange={event => handleAddAttr(event.target.value, idx, "telefone")}
                                                            InputProps={{
                                                                readOnly: !editing,
                                                                inputComponent: TelTextMask
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <TextField
                                                            size="small"
                                                            disabled={running}
                                                            InputLabelProps={{ shrink: true }}
                                                            id={`"celular_"${idx}`}
                                                            name="celular"
                                                            label="Celular"
                                                            value={field["celular"]}
                                                            onChange={event => handleAddAttr(event.target.value, idx, "celular")}
                                                            InputProps={{
                                                                readOnly: !editing,
                                                                inputComponent: CelTextMask
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </React.Fragment>
                                            )
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Accordion expanded={true}>
                                <AccordionSummary expandIcon={false} aria-controls="panel1d-content" id="panel-tel-header">
                                    <Typography>Emails do financeiro</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    {editing && <Grid item xs={12} sm={4} marginBottom={1}>
                                        <ButtonGroup disabled={running} style={{ height: "20px" }} variant="outlined" size="small" aria-label="small button group">
                                            <Button
                                                disableElevation
                                                onClick={() => {
                                                    let emailsFinanceirosList = inputFields.emailsFinanceiros;
                                                    emailsFinanceirosList.push("");
                                                    setInputFields({ ...inputFields, emailsFinanceiros: emailsFinanceirosList });
                                                }}>
                                                <Add /></Button>
                                            <Button
                                                disableElevation
                                                onClick={() => {
                                                    if (inputFields.emailsFinanceiros.length > 1) {
                                                        let emailsFinanceirosList = inputFields.emailsFinanceiros;
                                                        emailsFinanceirosList.pop();
                                                        setInputFields({ ...inputFields, emailsFinanceiros: emailsFinanceirosList });
                                                    }
                                                }}>
                                                <Remove /></Button>
                                        </ButtonGroup>
                                    </Grid>}
                                    <Grid container spacing={3}>
                                        {inputFields.emailsFinanceiros.map((field, idx) => {
                                            return (
                                                <React.Fragment key={idx}>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            size="small"
                                                            disabled={running}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                readOnly: !editing
                                                            }}
                                                            id={`"email_"${idx}`}
                                                            name="email"
                                                            label="Email"
                                                            value={field}
                                                            onChange={event => handleEmail(event.target.value, idx, "emailsFinanceiros")}
                                                            inputProps={{
                                                                maxLength: 50
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </React.Fragment>
                                            )
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Accordion expanded={true}>
                                <AccordionSummary expandIcon={false} aria-controls="panel1d-content" id="panel-tel-header">
                                    <Typography>Emails da contabilidade</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    {editing && <Grid item xs={12} sm={4} marginBottom={1}>
                                        <ButtonGroup disabled={running} style={{ height: "20px" }} variant="outlined" size="small" aria-label="small button group">
                                            <Button
                                                disableElevation
                                                onClick={() => {
                                                    let emailsContabeisList = inputFields.emailsContabeis;
                                                    emailsContabeisList.push("");
                                                    setInputFields({ ...inputFields, emailsContabeis: emailsContabeisList });
                                                }}>
                                                <Add /></Button>
                                            <Button
                                                disableElevation
                                                onClick={() => {
                                                    if (inputFields.emailsContabeis.length > 1) {
                                                        let emailsContabeisList = inputFields.emailsContabeis;
                                                        emailsContabeisList.pop();
                                                        setInputFields({ ...inputFields, emailsContabeis: emailsContabeisList });
                                                    }
                                                }}>
                                                <Remove /></Button>
                                        </ButtonGroup>
                                    </Grid>}
                                    <Grid container spacing={3}>
                                        {inputFields.emailsContabeis.map((field, idx) => {
                                            return (
                                                <React.Fragment key={idx}>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            size="small"
                                                            disabled={running}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                readOnly: !editing
                                                            }}
                                                            id={`"email_"${idx}`}
                                                            name="email"
                                                            label="Email"
                                                            value={field}
                                                            onChange={event => handleEmail(event.target.value, idx, "emailsContabeis")}
                                                            inputProps={{
                                                                maxLength: 50
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </React.Fragment>
                                            )
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {editing ?
                            <Grid item >
                                <Grid container spacing={3} justifyContent="center">
                                    <Grid item xs={12} sm={6}>
                                        <LoadingButton
                                            loading={running}
                                            type="submit"
                                            startIcon={(<SaveIcon />)}
                                            color="primary"
                                            variant="contained"
                                            size="medium"
                                            disableElevation
                                            sx={{ width: "200px" }}
                                        >
                                            Salvar
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <LoadingButton
                                            loading={running}
                                            startIcon={(<CancelIcon />)}
                                            color="primary"
                                            variant='outlined'
                                            // variant="outlined"
                                            size="medium"
                                            disableElevation
                                            onClick={() => cancelSubmit()}
                                            sx={{ width: "200px" }}
                                        >
                                            Cancelar
                                        </LoadingButton>
                                    </Grid>
                                </Grid>

                            </Grid>
                            : null}
                    </Grid>
                </Paper>
            </Grid>
        </Container>
    );
}

export default ExporterView;
