import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isAdmin, isManager } from '../../services/auth';

import ImporterTable from "./importerTable";
import PageHeaderButton from '../../components/pageHeaderButton';
import { api } from '../../services/api';

const Importer = props => {
    const [importerList, setImporterList] = useState([]);

    useEffect(() => {
        let mounted = true;
        api.getImporters()
            .then(resp => {
                if (mounted && resp) {
                    setImporterList(resp);
                }
            })
        return () => mounted = false;
    }, [])

    return (
        <Container maxWidth="xg" sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={12}>
            <PageHeaderButton canCreate={isAdmin() || isManager()} title="Clientes" path="/importers" ></PageHeaderButton>
            </Grid>
            <Grid item xs={12}>
                <ImporterTable rows={importerList}/>
            </Grid>
        </Container>
    );

};

export default Importer;