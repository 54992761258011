import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';

import App from './main/App';
import { store } from './store';
import { QueryClientProvider } from 'react-query';
import queryClient from './query/queryClient.js';

import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <QueryClientProvider client={queryClient}>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </QueryClientProvider>
);
