import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { formatFloatToReal } from '../../common/utils';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function descendingComparator(a, b, orderBy) {
    let elemA = b[orderBy];
    let elemB = a[orderBy];

    if (orderBy === 'creationDate') {
        let dateA = dayjs(elemA);
        let dateB = dayjs(elemB);
        if (dateB.isBefore(dateA)) {
            return 1;
        }
        if (dateA.isBefore(dateB)) {
            return -1;
        }
        return 0;
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'vendaCode',
        numeric: false,
        disablePadding: false,
        label: 'Venda',
    },
    {
        id: 'importadora',
        numeric: false,
        disablePadding: false,
        label: 'Cliente',
    },
    {
        id: 'banco',
        numeric: false,
        disablePadding: false,
        label: 'Banco',
    },
    {
        id: 'contratoBacen',
        numeric: false,
        disablePadding: false,
        label: 'Contrato Bacen',
    },
    {
        id: 'contratoBanco',
        numeric: false,
        disablePadding: false,
        label: 'Contrato Banco',
    },
    {
        id: 'valorRecebido',
        numeric: false,
        disablePadding: false,
        label: 'Recebimento',
    },
    {
        id: 'creationDate',
        numeric: false,
        disablePadding: false,
        label: 'Data',
    },
    {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: 'Ações',
    }
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ fontSize: '12px', fontWeight: 'bold' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    // numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const ReceivingTable = props => {
    const { rows } = props;
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('creationDate');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const navigate = useNavigate();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const goToReceiving = (id) => {
        navigate(`/receiving/${id}`, { id: id });
    }

    const handleClick = (event, id) => {
        goToReceiving(id);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Card variant="outlined" sx={{ borderRadius: '10px' }}>
                <CardContent>
                    <Grid container alignItems="center">
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size="small"
                            >
                                <EnhancedTableHead
                                    // numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    // onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                />
                                <TableBody>
                                    {stableSort(rows, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    // onClick={(event) => handleClick(event, row.id)}
                                                    // role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                // selected={isItemSelected}
                                                >
                                                    <TableCell>
                                                        {/* <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        /> */}
                                                    </TableCell>
                                                    <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">KAV-{row.vendaCode}</TableCell>
                                                    <TableCell style={{ width: '30%', fontSize: '11px' }} align="left" padding="normal">{row.importadora}</TableCell>
                                                    <TableCell style={{ width: '20%', fontSize: '11px' }} align="left" padding="normal">{row.banco}</TableCell>
                                                    <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{row.contratoBacen}</TableCell>
                                                    <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{row.contratoBanco}</TableCell>
                                                    <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{"$ " + formatFloatToReal(row.valorRecebido, 2)}</TableCell>
                                                    <TableCell style={{ width: '30%', fontSize: '11px' }} align="left" padding="normal">{dayjs(row.creationDate).format('DD/MM/YYYY, hh:mm A').toString()}</TableCell>
                                                    <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">
                                                        <IconButton
                                                            onClick={(event) => handleClick(event, row.id)}
                                                            size='small'>
                                                            <VisibilityIcon fontSize='small' />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (dense ? 33 : 53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[50, 100, 200, 500]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
};

export default ReceivingTable;