import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    Typography,
    Paper,
    Box,
    Fade,
    Button,
    InputAdornment
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

import { styled } from '@mui/material/styles';
import { Add, Remove } from '@mui/icons-material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import LinearProgress from '@mui/material/LinearProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import dayjs from 'dayjs';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ButtonGroup from '@mui/material/ButtonGroup';

import Autocomplete from '@mui/material/Autocomplete';

import { api } from '../../services/api';

import {
    MoneyTextMask,
} from '../../components/masks';

import ValueCard from './cardValue.jsx';
import { formatFloatToReal, formatRealToFloat, sumPreReceivedValues, sumPreReceivedValuesClosed } from '../../common/utils';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

function descendingComparator(a, b, orderBy) {
    let elemA = b[orderBy];
    let elemB = a[orderBy];

    if (orderBy === 'dataLiquidacaoContrato' || orderBy === 'dataContrato') {
        elemA = elemA.split('/');
        elemB = elemB.split('/');
        let dateA = elemA[1] + '-' + elemA[0] + '-' + elemA[2]
        let dateB = elemB[1] + '-' + elemB[0] + '-' + elemB[2]
        dateA = new Date(dateA);
        dateB = new Date(dateB);
        dateA = dayjs(dateA);
        dateB = dayjs(dateB);
        if (dateB.isBefore(dateA)) {
            return -1;
        }
        if (dateA.isBefore(dateB)) {
            return 1;
        }
        return 0;
    }
    if (elemB < elemA) {
        return -1;
    }
    if (elemB > elemA) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const createNewReceiving = async (allReceivedValues, saleChecked, itemFiles) => {
    try {
        const body = {
            "venda": saleChecked.id,
            "acc": "",
            "valorRecebido": ""
        }
        for (const key of Object.keys(itemFiles)) {
            const formData = new FormData();
            for (let i = 0; i < itemFiles[key].length; i++) {
                if (itemFiles[key][i]["file"] !== "" && itemFiles[key][i]["field"] !== "") {
                    formData.append(itemFiles[key][i]["field"], itemFiles[key][i]["file"]);
                }
            }

            body.acc = key;
            if (allReceivedValues[key] !== undefined && realToFloat(allReceivedValues[key]) > 0) {
                body.valorRecebido = realToFloat(allReceivedValues[key]);
                const jsonBody = JSON.stringify(body);
                const blob = new Blob([jsonBody], {
                    type: 'application/json'
                });
                formData.append("body", blob);
                const resp = await api.postReceiving(formData);
                if (!resp) {
                    console.log("Problema ao criar recebimento")
                }
                formData.delete("body");
            }
        }
        return true;
    } catch (err) {
        console.log(err);
        return false;
    }
}

function sumAllReceivedValues(allReceivedValues) {
    let total = 0;
    for (const key of Object.keys(allReceivedValues)) {
        if (allReceivedValues[key] !== undefined && allReceivedValues[key] !== '') {
            total += realToFloat(allReceivedValues[key]);
        }
    }
    return total;
}

const receivingBase = {
    "allReceivedValues": {},
    "arquivos": {}
}

function sumReceivedValues(list) {
    let total = 0;
    list.forEach(element => {
        total += element.valorRecebido;
    });
    return total;
}

function formatFloatToDolar(value) {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

function realToFloat(value) {
    let newValue = value.replace(/\./g, "");
    newValue = newValue.replace(",", ".");
    return parseFloat(newValue);
}

export default function ReceivingBase() {
    const [running, setRunning] = useState(false);
    const [open, setOpen] = useState(false);
    const [inputFields, setInputFields] = useState(receivingBase);
    // const [doc, setDoc] = useState({ "docName": "", "docFile": "" });

    const [accList, setAccList] = useState([]);
    const [salesList, setSalesList] = useState([]);
    // const [bankList, setBankList] = useState([]);
    const [srcAccList, setSrcAccList] = useState([]);
    const [srcSaleList, setSrcSaleList] = useState([]);

    const [saleChecked, setSaleChecked] = React.useState([]);
    const [accChecked, setAccChecked] = React.useState([]);

    const [totalOpen, setTotalOpen] = useState(0);
    const [totalReceived, setTotalReceived] = useState(0);

    const [blockSend, setBlockSend] = useState([]);

    const [valuesListAcc, setValuesListAcc] = useState([]);
    const [accFilter, setAccFilter] = useState({
        "field": "",
        "selectedValuesList": [],
    });

    const [valuesListSale, setValuesListSale] = useState([]);
    const [saleFilter, setSaleFilter] = useState({
        "field": "",
        "selectedValuesList": [],
    });

    const [accFiles, setAccFiles] = useState([]);

    const mapFieldsBank = {
        "Banco": "razaoSocial",
        "Contrato": "contratoBanco",
        "Bacen": "contratoBacen"
    }

    const mapFieldsSale = {
        "Cliente": "razaoSocial",
        "Código": "code",
    }

    useEffect(() => {
        let mounted = true;
        api.getOpenAccs()
            .then(resp => {
                if (mounted && resp) {
                    resp.forEach(element => {
                        element.valorAberto = (element.valorMonetarioDolar - sumReceivedValues(element.recebimentos)).toFixed(4);
                        element.dataLiquidacaoContrato = dayjs(element.dataLiquidacaoContrato).format('DD/MM/YYYY').toString();
                        let blcks = [];
                        for (var i = 0; i < resp.length; i++) {
                            blcks.push(false);
                        }
                        setBlockSend(blcks);
                    });
                    let respOrdered = stableSort(resp, getComparator('asc', 'taxaJuros'));
                    setAccList(respOrdered);
                    setSrcAccList(respOrdered);
                    // setBankList(createBankList(respOrdered));
                }
            }
            )

        api.getOpenSales()
            .then(resp => {
                if (mounted && resp) {
                    resp.forEach(element => {
                        element.enable = (element.totalDolar - (element.entrada + sumReceivedValues(element.recebimentos) + sumPreReceivedValues(element.preRecebimento))) > 0;
                    });
                    setSalesList(resp);
                    setSrcSaleList(resp);
                }
            }
            )

        return () => {
            setInputFields(receivingBase);
            setSaleChecked([]);
            setAccChecked([]);
        };
    }, [running]);

    const handleFieldAccSelect = (value) => {
        if (value) {
            let valuesFiltered = [];
            if (mapFieldsBank[value] === "razaoSocial") {
                valuesFiltered = srcAccList.map(acc => acc.banco.razaoSocial);
            } else {
                valuesFiltered = srcAccList.map(acc => acc[mapFieldsBank[value]]);
            }
            setValuesListAcc(valuesFiltered.filter((item, index) => valuesFiltered.indexOf(item) === index))
            setAccFilter({ ...accFilter, "field": mapFieldsBank[value] });
        } else {
            setAccFilter((accFilter) => ({
                ...accFilter, "field": ""
            }
            ))
            setValuesListAcc([]);
        }
    }

    const handleFieldSaleSelect = (value) => {
        if (value) {
            let valuesFiltered = [];
            if (mapFieldsSale[value] === "razaoSocial") {
                valuesFiltered = srcSaleList.map(sale => sale.importadora.razaoSocial);
            } else {
                valuesFiltered = srcSaleList.map(sale => sale[mapFieldsSale[value]]);
            }
            setValuesListSale(valuesFiltered.filter((item, index) => valuesFiltered.indexOf(item) === index))
            setSaleFilter({ ...saleFilter, "field": mapFieldsSale[value] });
        } else {
            setSaleFilter((saleFilter) => ({
                ...saleFilter, "field": ""
            }))
            setValuesListSale([]);
        }
    }

    const handleValueAccSelect = (value) => {
        if (value) {
            setAccFilter({ ...accFilter, "selectedValuesList": value });
        } else {
            setAccFilter({ ...accFilter, "selectedValuesList": [] });
        }
    }

    const handleValueSaleSelect = (value) => {
        if (value) {
            setSaleFilter({ ...saleFilter, "selectedValuesList": value });
        } else {
            setSaleFilter({ ...saleFilter, "selectedValuesList": [] });
        }
    }

    const applyFilters = () => {
        let accFiltered = srcAccList;
        let saleFiltered = srcSaleList;
        if (accFilter.field === "razaoSocial") {
            if (accFilter.selectedValuesList.length > 0) {
                accFiltered = srcAccList.filter(a => accFilter.selectedValuesList.includes(a.banco.razaoSocial));
            } else {
                accFiltered = srcAccList;
            }
        }
        else {
            if (accFilter.selectedValuesList.length > 0) {
                accFiltered = srcAccList.filter(a => accFilter.selectedValuesList.includes(a[accFilter.field]));
            } else {
                accFiltered = srcAccList;
            }
        }
        if (saleFilter.field === "razaoSocial") {
            if (saleFilter.selectedValuesList.length > 0) {
                saleFiltered = srcSaleList.filter(a => saleFilter.selectedValuesList.includes(a.importadora.razaoSocial));
            } else {
                saleFiltered = srcSaleList;
            }
        }
        else {
            if (saleFilter.selectedValuesList.length > 0) {
                saleFiltered = srcSaleList.filter(a => saleFilter.selectedValuesList.includes(a[saleFilter.field]));
            } else {
                saleFiltered = srcSaleList;
            }
        }
        setSalesList(saleFiltered);
        setAccList(accFiltered);
    }

    // const handleBankSelect = (value) => {
    //     if (value) {
    //         let accOrdered = srcAccList.filter(a => a.banco.id === value.id);
    //         setAccList(accOrdered);
    //     } else {
    //         setAccList(srcAccList);
    //     }

    // }

    const handleChangePriority = (event) => {
        let ascDesc = "dataLiquidacaoContrato" === event.target.value ? "desc" : "asc";
        let accOrdered = stableSort(accList, getComparator(ascDesc, event.target.value));
        setAccList(accOrdered);
    };

    const handleToggle = (value, checked, exclude) => () => {
        const currentIndex = checked.indexOf(value);
        let newChecked = []

        if (exclude) {
            newChecked = [...checked.fill(0)];
        } else {
            newChecked = [...checked];
        }

        if (currentIndex === -1) {
            if (!exclude) {
                newChecked.push(value);
            } else {
                newChecked = [value];
                setTotalOpen((value.totalDolar - (value.entrada + sumReceivedValues(value.recebimentos))).toFixed(4));
            }
        } else {
            if (!exclude) {
                handleUpdateReceiving(value.id, undefined, undefined, undefined);
            }
            newChecked.splice(currentIndex, 1);
        }

        if (exclude) {
            setSaleChecked(newChecked);
        } else {
            setAccChecked(newChecked);
        }
    };

    const handleToggleAcc = (value, checked) => () => {
        const currentIndex = checked.indexOf(value);
        let newChecked = [...checked];
        let tempFiles = inputFields.arquivos;
        let tempAccFiles = accFiles;

        if (currentIndex === -1) {
            newChecked.push(value);
            tempFiles[value.id] = [];
            tempAccFiles[value.id] = { "nome": value.banco.razaoSocial, "contrato": value.contratoBanco, "bacen": value.contratoBacen };
        } else {
            handleUpdateReceiving(value.id, undefined, undefined, undefined);
            newChecked.splice(currentIndex, 1);
            delete tempFiles[value.id];
            delete tempAccFiles[value.id];
        }
        setAccChecked(newChecked);
        setInputFields({ ...inputFields, arquivos: tempFiles });
        setAccFiles(tempAccFiles);
    };

    const delay = ms => new Promise(res => {
        setTimeout(res, ms);
        setOpen(true)
    });

    const handleUpdateReceiving = (id, idx, value, openValueAcc) => {
        if (saleChecked[0]) {
            const totalOpen = (saleChecked[0].totalDolar - (saleChecked[0].entrada + sumReceivedValues(saleChecked[0].recebimentos))).toFixed(4);
            setTotalOpen(totalOpen);
        }

        let newReceivedValues = inputFields.allReceivedValues;
        let vv = 0;
        newReceivedValues[id] = value;
        if (value) {
            vv = formatRealToFloat(value);
        }
        if (vv - openValueAcc > 0) {
            let blk = blockSend;
            blk[idx] = true;
            setBlockSend(blk);
        } else {
            let blk = blockSend;
            blk[idx] = false;
            setBlockSend(blk);
        }
        setTotalReceived(sumAllReceivedValues(newReceivedValues));
        setInputFields({ ...inputFields, allReceivedValues: newReceivedValues })
    }

    const handleSubmit = async e => {
        setRunning(true);
        e.preventDefault();
        const resp = await createNewReceiving(inputFields["allReceivedValues"], saleChecked.filter(a => a !== 0)[0], inputFields["arquivos"]);
        if (resp) {
            await delay(1000);
            setInputFields(receivingBase);
            setSaleChecked([]);
            setAccChecked([]);
            setTotalOpen(0);
            setTotalReceived(0);
            setRunning(false);
        }
        setSaleChecked([]);
        setAccChecked([]);
        setTotalOpen(0);
        setTotalReceived(0);
        setRunning(false);
        // setTotalOpen(0);
        // setDoc({ "docName": "", "docFile": "" });
        window.location.reload(false);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleAddFile = (target, idx, id) => {
        let filesList = inputFields.arquivos;
        filesList[id][idx]["file"] = target.files[0];
        // filesList[id][idx]["file"] = target.files[0].name;
        setInputFields((inputFields) => ({
            ...inputFields, arquivos: filesList
        }));
    };

    const handleAddName = (target, idx, id) => {
        let filesList = inputFields.arquivos;
        filesList[id][idx]["field"] = target;
        setInputFields((inputFields) => ({
            ...inputFields, arquivos: filesList
        }));
    };

    // const handleUpload = (target) => {
    //     setDoc({ "docName": target.files[0].name, "docFile": target.files[0] });
    // };

    return (
        <Container
            component="form"
            maxWidth="xg"
            sx={{ mt: 4, mb: 4 }}
            onSubmit={handleSubmit}>
            <Grid item xs={12} sx={{ mb: 1 }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/receiving">
                        Recebimentos
                    </Link>
                    <Link underline="hover" color="text.primary" href="/receiving/create"
                    >
                        Novo
                    </Link>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar
                        open={open}
                        autoHideDuration={2000}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            O Recebimento foi inserido com sucesso!
                        </Alert>
                    </Snackbar>
                </Stack>

                <Paper variant="outlined" sx={{ width: '100%', mb: 1, borderRadius: '10px' }}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            ml: 2,
                            mr: 2,
                            mb: 0
                        }}
                    >
                        <Typography
                            sx={{ m: 2, fontSize: '14px' }}
                        >
                            Novo Recebimento
                        </Typography>
                    </Box>
                </Paper>
                <Fade
                    in={running}
                    style={{
                        transitionDelay: running ? '100ms' : '0ms',
                    }}
                    unmountOnExit
                >
                    <LinearProgress />
                </Fade>
                <Grid item xs={12} sx={{ mb: 1 }}>
                    <Paper variant="outlined" sx={{ my: { xs: 2, md: 0 }, p: { xs: 2, md: 2 }, borderRadius: '10px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Accordion expanded={true} style={{ borderRadius: '10px' }}>
                                    <AccordionSummary sx={{ height: "40px" }} expandIcon={false} style={{ minHeight: "0px", borderRadius: '10px', backgroundColor: "#fff" }} id="panel-tel-header">
                                        <Typography sx={{ fontSize: "12px" }}>Filtros</Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={3}>
                                                <Autocomplete
                                                    size="small"
                                                    disablePortal
                                                    id="field-select-1"
                                                    getOptionLabel={(option) => option}
                                                    options={Object.keys(mapFieldsSale)}
                                                    onChange={(event, value) => handleFieldSaleSelect(value)}
                                                    sx={{ width: "100%" }}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Campo da venda a ser filtrado"
                                                        InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                                        InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                                    />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Autocomplete
                                                    multiple
                                                    size="small"
                                                    disablePortal
                                                    id="value-select-1"
                                                    getOptionLabel={(option) => option}
                                                    options={valuesListSale}
                                                    onChange={(event, value) => handleValueSaleSelect(value)}
                                                    sx={{ width: "100%" }}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Conteúdo do campo da venda"
                                                        InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                                        InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                                    />
                                                    }

                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Autocomplete
                                                    size="small"
                                                    disablePortal
                                                    id="field-select-2"
                                                    getOptionLabel={(option) => option}
                                                    options={Object.keys(mapFieldsBank)}
                                                    onChange={(event, value) => handleFieldAccSelect(value)}
                                                    sx={{ width: "100%" }}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Campo do acc a ser filtrado"
                                                        InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                                        InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                                    />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Autocomplete
                                                    multiple
                                                    size="small"
                                                    disablePortal
                                                    id="value-select-2"
                                                    getOptionLabel={(option) => option}
                                                    options={valuesListAcc}
                                                    onChange={(event, value) => handleValueAccSelect(value)}
                                                    sx={{ width: "100%" }}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Conteúdo do campo do acc"
                                                        InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                                        InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                                    />
                                                    }

                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12} sm={1}>
                                <Button
                                    style={{ textTransform: 'none', fontSize: '12px' }}
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                    onClick={() => applyFilters()}
                                >
                                    Aplicar
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Paper variant="outlined" sx={{ my: { xs: 2, md: 0 }, p: { xs: 2, md: 2 }, borderRadius: '10px' }}>
                    <Grid
                        container spacing={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm={12}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontSize: "12px" }}>Ordenar contratos por prioridade de:</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    onChange={handleChangePriority}
                                    defaultValue="taxaJuros"
                                >
                                    <FormControlLabel value="dataLiquidacaoContrato" control={<Radio style={{ height: "5px" }} size='small' />}
                                        label={<Typography style={{ fontSize: "12px" }}>Vencimento</Typography>} />
                                    <FormControlLabel value="taxaJuros" control={<Radio size='small' />} label={<Typography style={{ fontSize: "12px" }}>Taxa de Juros</Typography>} />
                                    <FormControlLabel value="valorAberto" control={<Radio size='small' />} label={<Typography style={{ fontSize: "12px" }}>Valor em Aberto</Typography>} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Accordion expanded={true} style={{ borderRadius: '10px' }}>
                                <AccordionSummary sx={{ height: "40px" }} expandIcon={false} style={{ minHeight: "0px", borderRadius: '10px', backgroundColor: "#fff" }} id="panel-tel-header">
                                    <Typography sx={{ fontSize: "12px" }}>Vendas</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid item xs={12} sm={12}>

                                    </Grid>
                                    <Box
                                        sx={{
                                            m: 1,
                                            // border: 1,
                                            width: '100%',
                                            // maxWidth: 800,
                                            borderColor: 'grey.100',
                                            // borderRadius: '10px'
                                        }}
                                    >
                                        <List
                                            sx={{
                                                width: '100%',
                                                // maxWidth: 800,
                                                position: 'relative',
                                                overflow: 'auto',
                                                height: 500,
                                                '& ul': { padding: 0 },
                                            }}
                                            subheader={<li />}
                                        >
                                            {salesList && salesList.map((item, idx) => {
                                                const labelId = `checkbox-list-label-${item}`;

                                                if (!item.enable) {
                                                    return null;
                                                }

                                                return (
                                                    <ListItem
                                                        key={idx}
                                                        disablePadding
                                                    >
                                                        <ListItemButton
                                                            selected={saleChecked.indexOf(item) !== -1}
                                                            role={undefined}
                                                            onClick={handleToggle(item, saleChecked, true)}
                                                            dense
                                                        >
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={saleChecked.indexOf(item) !== -1}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                    size='small'
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                id={labelId}
                                                                primary={
                                                                    <React.Fragment>
                                                                        <Typography
                                                                            sx={{ display: 'list-item', fontSize: '11px' }}
                                                                        >
                                                                            {item.importadora.razaoSocial}

                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{ display: 'list-item', fontSize: '11px' }}
                                                                            component="span"
                                                                            variant="body1"
                                                                            color="text.secondary"
                                                                        >
                                                                            {"KAV - " + item.code}
                                                                        </Typography>
                                                                    </React.Fragment>
                                                                }
                                                                secondary={<React.Fragment>
                                                                    <Typography
                                                                        sx={{ display: 'list-item', fontSize: '11px' }}
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="text.secondary"
                                                                    >
                                                                        Valor pendente: {formatFloatToDolar(item.totalDolar - (item.entrada + sumReceivedValues(item.recebimentos) + sumPreReceivedValuesClosed(item.preRecebimento)))}
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{ display: 'list-item', fontSize: '11px' }}
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="text.secondary"
                                                                    >
                                                                        Produto: {item.produto.nome}
                                                                    </Typography>
                                                                </React.Fragment>} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Accordion expanded={true} style={{ borderRadius: '10px' }}>
                                <AccordionSummary sx={{ height: "40px" }} expandIcon={false} style={{ minHeight: "0px", borderRadius: '10px', backgroundColor: "#fff" }} id="panel-tel-header">
                                    <Typography sx={{ fontSize: "12px" }}>Contratos</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box
                                        sx={{
                                            m: 1,
                                            // border: 1,
                                            width: '100%',
                                            // maxWidth: 800,
                                            // borderColor: 'grey.300',
                                            // borderRadius: '4px'
                                        }}
                                    >
                                        <List
                                            sx={{
                                                width: '100%',
                                                // maxWidth: 800,
                                                position: 'relative',
                                                overflow: 'auto',
                                                height: 500,
                                                '& ul': { padding: 0 },
                                            }}
                                            subheader={<li />}
                                        >
                                            {accList && accList.map((item, idx) => {
                                                const labelId = `checkbox-list-label-${item}`;

                                                return (
                                                    <React.Fragment key={idx}>
                                                        <ListItem
                                                            disablePadding
                                                        >
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} sm={12}>
                                                                    <ListItemButton
                                                                        selected={accChecked.indexOf(item) !== -1}
                                                                        role={undefined}
                                                                        onClick={handleToggleAcc(item, accChecked)}
                                                                        dense
                                                                    >
                                                                        <ListItemIcon>
                                                                            <Checkbox
                                                                                edge="start"
                                                                                checked={accChecked.indexOf(item) !== -1}
                                                                                tabIndex={-1}
                                                                                disableRipple
                                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                                                size='small'
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            id={labelId}
                                                                            primary={
                                                                                <React.Fragment>
                                                                                    <Typography
                                                                                        sx={{ display: 'list-item', fontSize: '11px' }}
                                                                                    >
                                                                                        {item.banco.razaoSocial}

                                                                                    </Typography>
                                                                                    <Typography
                                                                                        sx={{ display: 'list-item', fontSize: '11px' }}
                                                                                        component="span"
                                                                                        variant="body1"
                                                                                        color="text.secondary"
                                                                                    >
                                                                                        Contrato: {item.contratoBanco} / Bacen: {item.contratoBacen}
                                                                                    </Typography>
                                                                                </React.Fragment>
                                                                            }
                                                                            secondary={<React.Fragment>
                                                                                <Typography
                                                                                    sx={{ display: 'list-item', fontSize: '11px' }}
                                                                                    component="span"
                                                                                    variant="body2"
                                                                                    color="text.secondary"
                                                                                >
                                                                                    Valor em aberto: ${formatFloatToReal(parseFloat(item.valorAberto))} / Valor quitado: ${formatFloatToReal(sumReceivedValues(item.recebimentos))}
                                                                                </Typography>
                                                                                <Typography
                                                                                    sx={{ display: 'list-item', fontSize: '11px' }}
                                                                                    component="span"
                                                                                    variant="body2"
                                                                                    color="text.secondary"
                                                                                >
                                                                                    Taxa de juros: {item.taxaJuros} % a.a. / Data limite: {item.dataLiquidacaoContrato}
                                                                                </Typography>
                                                                            </React.Fragment>} />
                                                                    </ListItemButton>
                                                                </Grid>
                                                                {!saleChecked.every(item => item === 0) && accChecked.indexOf(item) !== -1 &&
                                                                    <Grid item xs={12} sm={5} key={idx}
                                                                        sx={{
                                                                            margin: '0px 0px 10px 70px'
                                                                        }}>
                                                                        <TextField
                                                                            size='small'
                                                                            error={blockSend[idx]}
                                                                            helperText={blockSend[idx] ? "Valor maior que o valor em aberto do acc" : ""}
                                                                            id={`dolarValue-${idx + 1}`}
                                                                            name="dolarValue"
                                                                            label="Valor recebido"
                                                                            value={inputFields.allReceivedValues[item.id]}
                                                                            onChange={event => handleUpdateReceiving(item.id, idx, event.target.value, item.valorAberto)}
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start" style={{ fontSize: '12px' }}>$</InputAdornment>,
                                                                                inputComponent: MoneyTextMask,
                                                                                style: { fontSize: "12px" }
                                                                            }}
                                                                            InputLabelProps={{ style: { fontSize: "12px" } }}
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                }
                                                            </Grid>

                                                        </ListItem>
                                                        <Divider />
                                                    </React.Fragment>

                                                );
                                            })}
                                        </List>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {accChecked && accChecked.map((fieldAcc, idxAcc) => {
                            return (
                                <Grid item xs={12} sm={4} key={idxAcc}>
                                    <Accordion expanded={true} style={{ borderRadius: '10px', height: "400px" }}>
                                        <AccordionSummary sx={{ height: "40px" }} expandIcon={false} style={{ minHeight: "0px", borderRadius: '10px', backgroundColor: "#fff" }} id="panel-tel-header">
                                            <Typography sx={{ fontSize: "12px" }}>Arquivos para {accFiles[fieldAcc.id].nome} - Contrato {accFiles[fieldAcc.id].contrato}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Stack spacing={1}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={12}>
                                                        <ButtonGroup disabled={running} style={{ height: "14px" }} variant="outlined" size="small" aria-label="small button group">
                                                            <Button
                                                                disableElevation
                                                                onClick={() => {
                                                                    let filesList = inputFields.arquivos;
                                                                    filesList[fieldAcc.id].push({ "field": "", "file": "" });
                                                                    setInputFields({ ...inputFields, arquivos: filesList });
                                                                }}>
                                                                <Add style={{ fontSize: "12px" }} /></Button>
                                                            <Button
                                                                disableElevation
                                                                onClick={() => {
                                                                    if (inputFields.arquivos[fieldAcc.id].length > 0) {
                                                                        let filesList = inputFields.arquivos;
                                                                        filesList[fieldAcc.id].pop();
                                                                        setInputFields({ ...inputFields, arquivos: filesList });
                                                                    }
                                                                }}>
                                                                <Remove style={{ fontSize: "12px" }} /></Button>
                                                        </ButtonGroup>
                                                    </Grid>

                                                    {inputFields.arquivos && inputFields.arquivos[fieldAcc.id].map((field, idx) => {
                                                        return (
                                                            <React.Fragment key={idx + idxAcc}>
                                                                <Grid item xs={12} sm={5}>
                                                                    <TextField
                                                                        fullWidth
                                                                        size="small"
                                                                        disabled={running}
                                                                        id={`${idx + idxAcc}`}
                                                                        name="filename"
                                                                        label={`Arquivo ${idx + 1}`}
                                                                        value={field["field"]}
                                                                        onChange={event => handleAddName(event.target.value, idx, fieldAcc.id)}
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        size="small"
                                                                        disabled={running}
                                                                        id={`${idx + idxAcc}`}
                                                                        name="arquivo"
                                                                        label={`Arquivo ${idx + 1}`}
                                                                        value={field["file"] && field["file"].name}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        InputProps={{
                                                                            readOnly: true,
                                                                            endAdornment:
                                                                                <Button
                                                                                    size="small"
                                                                                    component="label"
                                                                                    color="primary"
                                                                                    variant="outlined">
                                                                                    <input
                                                                                        hidden
                                                                                        accept="pdf/*"
                                                                                        multiple
                                                                                        type="file"
                                                                                        onChange={event => handleAddFile(event.target, idx, fieldAcc.id)}
                                                                                    >
                                                                                    </input>
                                                                                    <UploadFileIcon />
                                                                                </Button>
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </Grid>
                                            </Stack>

                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            )
                        })}
                        <Grid item xs={12} sm={12}>
                            <ValueCard
                                message='Total do Recebimento'
                                values={formatFloatToDolar(totalReceived)}
                            >
                            </ValueCard>
                        </Grid>
                        {(totalOpen - totalReceived) >= 0 && blockSend.every(element => element === false) && <Grid item xs={12} sm={12}>
                            <Grid container>
                                <LoadingButton
                                    loading={running}
                                    type="submit"
                                    startIcon={(<SaveIcon />)}
                                    // loadingPosition="start"
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    disableElevation
                                    sx={{ width: "200px" }}
                                // onClick={() => createNewProduct(inputFields)}
                                >
                                    Salvar
                                </LoadingButton>
                            </Grid>
                        </Grid>}
                        {(totalOpen - totalReceived) < 0 && <Grid item xs={12} sm={12}>
                            <Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert variant="outlined" sx={{ boxShadow: 0 }} severity="error">O total de recebimentos é maior que o valor a receber para esta venda</Alert>
                            </Stack>
                        </Grid>}
                    </Grid>
                </Paper>
            </Grid>
        </Container>
    );
}