import React from 'react';
import { Box, Typography, Paper } from '@mui/material';


export default function PageHeader(props) {
    return (
        <Paper variant="outlined" sx={{ width: '100%', mb: 1, borderRadius: '10px' }}>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    ml: 1,
                }}
            >
                <Typography
                    sx={{ m: 2, fontSize: '14px', fontWeight: 'bold' }}
                >
                    {props.title}
                </Typography>
            </Box>
        </Paper>
    )
}
