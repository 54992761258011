import { Box, Button, Paper, Typography } from '@mui/material';

import { Add } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";

const PageHeaderButton = props => {
    const navigate = useNavigate();
    const { title, path, canCreate } = props;

    function goToCreate() {
        navigate(`${path}/create`);
    }
    return (
        <Paper variant="outlined" sx={{ width: '100%', mb: 1, borderRadius: '10px' }}>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    ml: 2,
                    mr: 2,
                    mb: 0
                }}
                >
                <Typography
                    sx={{ m: 2, fontSize: '14px', fontWeight: 'bold' }}
                    >
                    {title}
                </Typography>
                <Box sx={{ m: 1 }}>
                    {canCreate && 
                        <Button
                            style={{textTransform: 'none', fontSize: '10px'}}
                            startIcon={(<Add style={{fontSize: "10px"}}/>)}
                            color="primary"
                            variant="contained"
                            size="small"
                            disableElevation
                            onClick={() => goToCreate()}
                            >
                            Adicionar
                        </Button>
                    }
                </Box>
            </Box>
        </Paper>
    )
};

export default PageHeaderButton;