import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Paper,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Box,
  Button,
  Fade
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import { Edit } from "@mui/icons-material";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { api } from '../../services/api';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const ProductView = (props) => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [productBack, setProductBack] = useState({});
  const navigate = useNavigate();

  const [editing, setEditing] = useState(false);
  const [running, setRunning] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const updateProduct = async (item, id) => {
    const resp = api.putProduct(JSON.stringify(item), id);
    return resp;
  }

  const deleteProduct = async id => {
    const resp = api.delProduct(id);
    return resp;
  }

  const delay = ms => new Promise(res => {
    setTimeout(res, ms);
    setOpen(true)
  });

  const handleClose = () => {
    setRunning(false);
    setOpen(false);
  };

  const handleEdit = async id => {
    setEditing(true);
  }

  const handleSubmit = async e => {
    setRunning(true);
    e.preventDefault();
    const resp = await updateProduct(product, id);
    if (resp) {
      setMessage("Produto atualizado!");
      setSeverity("success");
      setProduct(resp);
      setProductBack(resp);
    } else {
      setMessage("Erro ao atualizar produto!");
      setSeverity("error");
      setProduct(productBack);
    }
    setOpen(true);
    setRunning(false);
    setEditing(false);
  }

  const cancelSubmit = () => {
    setProduct(productBack);
    setEditing(false);
  }

  useEffect(() => {
    let mounted = true;
    api.getOneProduct(id)
      .then(resp => {
        if (mounted) {
          setProduct(resp);
          setProductBack(resp);
        }
      })
    return () => mounted = false;
  }, [])
  return (
    <Container
      maxWidth="xg"
      sx={{ mt: 4, mb: 4 }}
      component="form"
      onSubmit={handleSubmit}>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}>
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>

      <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 }, borderRadius: '10px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Button
              style={{ textTransform: 'none', fontSize: '10px', marginBottom: "20px" }}
              startIcon={(<Edit style={{ fontSize: "10px" }} />)}
              sx={{ mr: 2 }}
              size="small"
              variant="contained"
              disabled={editing || running}
              disableElevation
              onClick={() => handleEdit(id)}
            >
              Editar
            </Button>
            <Accordion expanded={true} style={{ borderRadius: '10px'}}>
              <AccordionSummary sx={{ height: "40px" }} expandIcon={false} style={{ minHeight: "0px", borderRadius: '10px', backgroundColor: "#fff" }} id="panel-tel-header">
                <Typography sx={{ fontSize: "12px" }}>Informações Gerais</Typography>

              </AccordionSummary>
              <AccordionDetails style={{ borderColor: "#fff"}}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size='small'
                      disabled={running}
                      InputProps={{
                        readOnly: !editing,
                        style: { fontSize: "12px" },
                        sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }}}
                        InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                      id="fullName"
                      name="fullName"
                      label="Nome"
                      value={product.nome}
                      onChange={event => setProduct({ ...product, nome: event.target.value })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      size='small'
                      disabled={running}
                      InputProps={{
                        readOnly: !editing,
                        style: { fontSize: "12px" },
                        sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                      }}
                      InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                      id="erpCode"
                      name="erpCode"
                      label="Codígo ERP"
                      value={product.codigoERP}
                      onChange={event => setProduct({ ...product, codigoERP: event.target.value })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      size='small'
                      disabled={running}
                      InputProps={{
                        readOnly: !editing,
                        style: { fontSize: "12px" },
                        sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                      }}
                      InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                      id="ncm-code"
                      name="ncm-code"
                      label="Código NCM"
                      value={product.classificacaoNCM}
                      onChange={event => setProduct({ ...product, classificacaoNCM: event.target.value })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size='small'
                      disabled={running}
                      InputProps={{
                        readOnly: !editing,
                        style: { fontSize: "12px" },
                        sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                      }}
                      InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                      id="description"
                      name="description"
                      label="Descrição"
                      value={product.descricao}
                      onChange={event => setProduct({ ...product, descricao: event.target.value })}
                      fullWidth
                    />
                  </Grid>
                  {editing ?
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="input-unit">Unidade</InputLabel>
                        <Select
                          size='small'
                          required
                          disabled={running}
                          InputLabelProps={{ shrink: true, style: { fontSize: "10px" } }}
                          InputProps={{
                            readOnly: !editing,
                            style: { fontSize: "10px" },
                            sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                          }}
                          labelId="unidade"
                          id="unidade"
                          label="Unidade"
                          value={product.unidadeMedida}
                          onChange={event => setProduct({ ...product, unidadeMedida: event.target.value })}
                        >
                          <MenuItem value={"Caixa"}>Caixa</MenuItem>
                          <MenuItem value={"Quilo"}>Quilo</MenuItem>
                          <MenuItem value={"Saco"}>Saco</MenuItem>
                          <MenuItem value={"Tonelada"}>Tonelada</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid> :
                    <Grid item xs={12} sm={6}>
                      <TextField
                        size='small'
                        disabled={running}
                        InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                        InputProps={{
                          readOnly: !editing,
                          style: { fontSize: "12px" },
                          sx: editing ? { backgroundColor: '#fff' } : { backgroundColor: '#fafafa' }
                        }}
                        id="unidade"
                        name="unidade"
                        label="Unidade"
                        value={product.unidadeMedida}
                        fullWidth
                      />
                    </Grid>
                  }
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {editing ?
            <Grid item >
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={6}>
                  <LoadingButton
                    style={{ textTransform: 'none', fontSize: '10px' }}
                    loading={running}
                    type="submit"
                    startIcon={(<SaveIcon style={{ fontSize: "10px" }} />)}
                    color="primary"
                    variant="contained"
                    size="medium"
                    disableElevation
                  // sx={{ width: "100px" }}
                  >
                    Salvar
                  </LoadingButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LoadingButton
                    style={{ textTransform: 'none', fontSize: '10px' }}
                    loading={running}
                    startIcon={(<CancelIcon style={{ fontSize: "10px" }} />)}
                    color="primary"
                    variant='outlined'
                    // variant="outlined"
                    size="medium"
                    disableElevation
                    onClick={() => cancelSubmit()}
                  // sx={{ width: "100px" }}
                  >
                    Cancelar
                  </LoadingButton>
                </Grid>
              </Grid>

            </Grid>
            : null}
        </Grid>
      </Paper>
    </Container>
  );
}

export default ProductView;
