import React, { useState } from 'react';
import {
    Container,
    Grid,
    Typography,
    Paper,
    Box,
    Button
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import TextField from '@mui/material/TextField';

import { styled } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ButtonGroup from '@mui/material/ButtonGroup';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { LoadingButton } from '@mui/lab';

import {
    IdentifierTextMask,
    NumberTextMask,
    InterCelTextMask
} from '../../components/masks';

import { api } from '../../services/api';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function ImporterCreate() {
    const base = {
        "tipo": "Jurídica",
        "razaoSocial": "",
        "cnpj": "",
        "ativoDesde": "",
        "contatos": [
            {
                "nome": "",
                "email": "",
                "celular": "",
                "telefone": ""
            }
        ],
        "endereco": {
            "logradouro": "",
            "numero": "",
            "complemento": "",
            "bairro": "",
            "cidade": "",
            "estado": "",
            "pais": "",
            "zipCode": "",
            "caixaPostal": "null"
        },
        "taxId": "",
        "codigoERP": ""
    }

    const [inputFields, setInputFields] = useState(base);

    const [running, setRunning] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const createNewImporter = async item => {
        const resp = api.postImporter(JSON.stringify(item));
        return resp;
    }

    const handleSubmit = async e => {
        setRunning(true);
        e.preventDefault();
        const resp = await createNewImporter(inputFields);
        if (resp) {
            setMessage("Cliente cadastrado!");
            setSeverity("success");
            setInputFields(base);
        } else {
            setMessage("Erro ao cadastrar cliente!");
            setSeverity("error");
        }
        setOpen(true);
    }

    const handleClose = () => {
        setRunning(false);
        setOpen(false);
    };

    const handleAddAttr = (target, idx, field) => {
        let contatosList = inputFields.contatos;
        contatosList[idx][field] = target;
        setInputFields((inputFields) => ({
            ...inputFields, contatos: contatosList
        }));
    };

    return (
        <Container component="form"
            maxWidth="xg"
            sx={{ mt: 4, mb: 4 }}
            onSubmit={handleSubmit}>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>
            <Paper variant="outlined" sx={{ width: '100%', mb: 1, borderRadius: '10px' }}>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        ml: 2,
                        mr: 2,
                        mb: 0
                    }}
                >
                    <Typography
                        sx={{ m: 2, fontSize: '14px' }}
                    >
                        Novo Cliente
                    </Typography>
                </Box>
            </Paper>
            <Grid item xs={12}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 } }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Accordion expanded={true} style={{ borderRadius: '10px' }}>
                            <AccordionSummary sx={{ height: "40px" }} expandIcon={false} style={{ minHeight: "0px", borderRadius: '10px', backgroundColor: "#fff" }} id="panel-tel-header">
                                    <Typography sx={{ fontSize: "12px" }}>Informações Gerais</Typography>

                                </AccordionSummary>
                                <AccordionDetails >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                size="small"
                                                required
                                                disabled={running}
                                                id="name"
                                                name="name"
                                                label="Nome/Razão Social"
                                                fullWidth
                                                value={inputFields.razaoSocial}
                                                onChange={event => setInputFields({ ...inputFields, razaoSocial: event.target.value })}
                                                inputProps={{
                                                    maxLength: 60
                                                }}
                                                InputProps={{ style: { fontSize: "12px" } }}
                                                InputLabelProps={{ style: { fontSize: "12px" } }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size="small"
                                                disabled={running}
                                                id="cnpj"
                                                name="cnpj"
                                                label="Identificador"
                                                value={inputFields.cnpj}
                                                onChange={event => setInputFields({ ...inputFields, cnpj: event.target.value })}
                                                InputProps={{
                                                    inputComponent: IdentifierTextMask,
                                                    style: { fontSize: "12px" }
                                                }}
                                                InputLabelProps={{ style: { fontSize: "12px" } }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size="small"
                                                required
                                                disabled={running}
                                                id="taxId"
                                                name="taxId"
                                                label="Tax ID"
                                                value={inputFields.taxId}
                                                onChange={event => setInputFields({ ...inputFields, taxId: event.target.value })}
                                                inputProps={{
                                                    maxLength: 15
                                                }}
                                                InputProps={{ style: { fontSize: "12px" } }}
                                                InputLabelProps={{ style: { fontSize: "12px" } }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size="small"
                                                disabled={running}
                                                id="codigoERP"
                                                name="codigoERP"
                                                label="Código ERP"
                                                value={inputFields.codigoERP}
                                                onChange={event => setInputFields({ ...inputFields, codigoERP: event.target.value })}
                                                inputProps={{
                                                    maxLength: 15
                                                }}
                                                InputProps={{ style: { fontSize: "12px" } }}
                                                InputLabelProps={{ style: { fontSize: "12px" } }}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Accordion expanded={true} style={{ borderRadius: '10px' }}>
                            <AccordionSummary sx={{ height: "40px" }} expandIcon={false} style={{ minHeight: "0px", borderRadius: '10px', backgroundColor: "#fff" }} id="panel-tel-header">
                                    <Typography sx={{ fontSize: "12px" }}>Endereço</Typography>

                                </AccordionSummary>
                                <AccordionDetails >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                size="small"
                                                required
                                                disabled={running}
                                                id="logradouro"
                                                name="logradouro"
                                                label="Logradouro"
                                                value={inputFields.endereco.logradouro}
                                                onChange={event => setInputFields(
                                                    { ...inputFields, endereco: { ...inputFields.endereco, logradouro: event.target.value } })}
                                                inputProps={{
                                                    maxLength: 60
                                                }}
                                                InputProps={{ style: { fontSize: "12px" } }}
                                                InputLabelProps={{ style: { fontSize: "12px" } }}
                                                fullWidth

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size="small"
                                                required
                                                disabled={running}
                                                id="numero"
                                                name="numero"
                                                label="Número"
                                                value={inputFields.endereco.numero}
                                                onChange={event => setInputFields(
                                                    { ...inputFields, endereco: { ...inputFields.endereco, numero: event.target.value } })}
                                                InputProps={{
                                                    inputComponent: NumberTextMask,
                                                    style: { fontSize: "12px" }
                                                }}
                                                InputLabelProps={{ style: { fontSize: "12px" } }}
                                                fullWidth

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                size="small"
                                                disabled={running}
                                                id="bairro"
                                                name="bairro"
                                                label="Bairro"
                                                value={inputFields.endereco.bairro}
                                                onChange={event => setInputFields(
                                                    { ...inputFields, endereco: { ...inputFields.endereco, bairro: event.target.value } })}
                                                inputProps={{
                                                    maxLength: 30
                                                }}
                                                InputProps={{ style: { fontSize: "12px" } }}
                                                InputLabelProps={{ style: { fontSize: "12px" } }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                size="small"
                                                disabled={running}
                                                id="complemento"
                                                name="complemento"
                                                label="Complemento"
                                                value={inputFields.endereco.complemento}
                                                onChange={event => setInputFields(
                                                    { ...inputFields, endereco: { ...inputFields.endereco, complemento: event.target.value } })}
                                                inputProps={{
                                                    maxLength: 20
                                                }}
                                                InputProps={{ style: { fontSize: "12px" } }}
                                                InputLabelProps={{ style: { fontSize: "12px" } }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                size="small"
                                                required
                                                disabled={running}
                                                id="cidade"
                                                name="cidade"
                                                label="Cidade"
                                                value={inputFields.endereco.cidade}
                                                onChange={event => setInputFields(
                                                    { ...inputFields, endereco: { ...inputFields.endereco, cidade: event.target.value } })}
                                                inputProps={{
                                                    maxLength: 30
                                                }}
                                                InputProps={{ style: { fontSize: "12px" } }}
                                                InputLabelProps={{ style: { fontSize: "12px" } }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size="small"
                                                required
                                                disabled={running}
                                                id="estado"
                                                name="estado"
                                                label="Estado"
                                                value={inputFields.endereco.estado}
                                                onChange={event => setInputFields(
                                                    { ...inputFields, endereco: { ...inputFields.endereco, estado: event.target.value } })}
                                                inputProps={{
                                                    maxLength: 20
                                                }}
                                                InputProps={{ style: { fontSize: "12px" } }}
                                                InputLabelProps={{ style: { fontSize: "12px" } }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size="small"
                                                required
                                                disabled={running}
                                                id="pais"
                                                name="pais"
                                                label="País"
                                                value={inputFields.endereco.pais}
                                                onChange={event => setInputFields(
                                                    { ...inputFields, endereco: { ...inputFields.endereco, pais: event.target.value } })}
                                                inputProps={{
                                                    maxLength: 20
                                                }}
                                                InputProps={{ style: { fontSize: "12px" } }}
                                                InputLabelProps={{ style: { fontSize: "12px" } }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                size="small"
                                                disabled={running}
                                                id="zipCode"
                                                name="zipCode"
                                                label="Zip Code"
                                                value={inputFields.endereco.zipCode}
                                                onChange={event => setInputFields(
                                                    { ...inputFields, endereco: { ...inputFields.endereco, zipCode: event.target.value } })}
                                                inputProps={{
                                                    maxLength: 12
                                                }}
                                                InputProps={{ style: { fontSize: "12px" } }}
                                                InputLabelProps={{ style: { fontSize: "12px" } }}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Accordion expanded={true} style={{ borderRadius: '10px' }}>
                            <AccordionSummary sx={{ height: "40px" }} expandIcon={false} style={{ minHeight: "0px", borderRadius: '10px', backgroundColor: "#fff" }} id="panel-tel-header">
                                    <Typography sx={{ fontSize: "12px" }}>Contatos</Typography>

                                </AccordionSummary>
                                <AccordionDetails >
                                    <Grid item xs={12} sm={4} marginBottom={1}>
                                        <ButtonGroup disabled={running} style={{height: "14px"}} variant="outlined" size="small" aria-label="small button group">
                                            <Button
                                                disableElevation
                                                onClick={() => {
                                                    let contatoList = inputFields.contatos;
                                                    contatoList.push({
                                                        "nome": "",
                                                        "email": "",
                                                        "celular": "",
                                                        "telefone": ""
                                                    });
                                                    setInputFields({ ...inputFields, contatos: contatoList });
                                                }}>
                                                <Add style={{fontSize: "12px"}} /></Button>
                                            <Button
                                                disableElevation
                                                onClick={() => {
                                                    if (inputFields.contatos.length > 1) {
                                                        let contatoList = inputFields.contatos;
                                                        contatoList.pop();
                                                        setInputFields({ ...inputFields, contatos: contatoList });
                                                    }
                                                }}>
                                                <Remove style={{fontSize: "12px"}} /></Button>
                                        </ButtonGroup>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        {inputFields.contatos.map((field, idx) => {
                                            return (
                                                <React.Fragment key={idx}>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            size="small"
                                                            required
                                                            disabled={running}
                                                            id={`"nome_"${idx}`}
                                                            name="nome"
                                                            label="Nome"
                                                            value={inputFields.contatos[idx]["nome"]}
                                                            onChange={event => handleAddAttr(event.target.value, idx, "nome")}
                                                            inputProps={{
                                                                maxLength: 40
                                                            }}
                                                            InputProps={{ style: { fontSize: "12px" } }}
                                                            InputLabelProps={{ style: { fontSize: "12px" } }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField
                                                            size="small"
                                                            disabled={running}
                                                            id={`"email_"${idx}`}
                                                            name="email"
                                                            label="Email"
                                                            value={inputFields.contatos[idx]["email"]}
                                                            onChange={event => handleAddAttr(event.target.value, idx, "email")}
                                                            inputProps={{
                                                                maxLength: 50
                                                            }}
                                                            InputProps={{ style: { fontSize: "12px" } }}
                                                            InputLabelProps={{ style: { fontSize: "12px" } }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <TextField
                                                            size="small"
                                                            disabled={running}
                                                            id={`"telefone_"${idx}`}
                                                            name="telefone"
                                                            label="Telefone"
                                                            value={inputFields.contatos[idx]["telefone"]}
                                                            onChange={event => handleAddAttr(event.target.value, idx, "telefone")}
                                                            InputProps={{
                                                                inputComponent: InterCelTextMask,
                                                                style: { fontSize: "12px" }
                                                            }}
                                                            InputLabelProps={{ style: { fontSize: "12px" } }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <TextField
                                                            size="small"
                                                            disabled={running}
                                                            id={`"celular_"${idx}`}
                                                            name="celular"
                                                            label="Celular"
                                                            value={inputFields.contatos[idx]["celular"]}
                                                            onChange={event => handleAddAttr(event.target.value, idx, "celular")}
                                                            InputProps={{
                                                                inputComponent: InterCelTextMask,
                                                                style: { fontSize: "12px" }
                                                            }}
                                                            InputLabelProps={{ style: { fontSize: "12px" } }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </React.Fragment>
                                            )
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <LoadingButton
                                    style={{textTransform: 'none', fontSize: '10px'}}
                                    loading={running}
                                    type="submit"
                                    startIcon={(<SaveIcon style={{fontSize: "10px"}}/>)}
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    disableElevation
                                >
                                    Salvar
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Container>
    );
}