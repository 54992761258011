import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Grid,
    Typography,
    Paper
} from '@mui/material';
import { useParams } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';

import FormCard from '../../components/forms/formCard';
import TextField from '@mui/material/TextField';
import { formatFloatToReal, sumReceivedValues } from '../../common/utils';
import dayjs from 'dayjs';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';

import Delete from '@mui/icons-material/Delete';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { api } from '../../services/api';
import { isAdmin } from '../../services/auth';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const ReceivingView = (props) => {
    const { id } = useParams();
    const [receiving, setReceiving] = useState({});
    const [sale, setSale] = useState({});
    const [acc, setAcc] = useState({});
    const [open, setOpen] = useState(false);
    const [running, setRunning] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [canDelete, setCanDelete] = useState(false);

    const navigate = useNavigate();

    const downloadFile = async (key) => {
        const resp = await api.getFileReceivings(receiving.id, key);
        const link = document.createElement('a');
        link.href = resp;
        link.setAttribute(
            'download',
            `FileName.pdf`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    const handleClose = () => {
        setRunning(false);
        setOpen(false);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const deleteReceiving = async id => {
        const resp = api.delReceiving(id);
        return resp;
    }

    const handleCloseDialog = async (needRemove) => {
        if (needRemove) {
            setRunning(true);
            setOpenDialog(false);
            const resp = await deleteReceiving(id);
            if (resp) {
                setMessage("Recebimento excluído com sucesso!");
                setSeverity("success");
                navigate("/receiving");
            } else {
                setMessage("Erro ao excluir Recebimento!");
                setSeverity("error");
                setRunning(false);
            }
        } else {
            setOpenDialog(false);
            setRunning(false);
        }
    };

    useEffect(() => {
        let mounted = true;
        api.getOneReceiving(id)
            .then(resp => {
                if (resp && mounted) {
                    setReceiving(resp);
                    api.getOneSale(resp.venda_id)
                        .then(s => {
                            if (s && mounted) {
                                setSale(s);
                            }
                        }
                        )
                    api.getOneAcc(resp.acc_id)
                        .then(b => {
                            if (b && mounted) {
                                setAcc(b);
                                if (isAdmin()) {
                                    setCanDelete(true);
                                }
                            }
                        }
                        )

                }
            })
        return () => mounted = false;
    }, [])

    return (
        <Container component="form"
            maxWidth="xg"
            sx={{ mt: 4, mb: 4 }}
            onSubmit={props.handleSubmit}>

            <Dialog
                open={openDialog}
                onClose={() => handleCloseDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Exclusão de Recebimento</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Certeza que deseja excluir o recebimento da venda KAV-{sale.code} para contrato {acc.contratoBanco} no valor de {"$ " + formatFloatToReal(receiving.valorRecebido, 2)} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseDialog(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => handleCloseDialog(true)} color="error" autoFocus>
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>

            <Paper variant="outlined" sx={{ p: { xs: 2, md: 2 }, borderRadius: '10px' }}>

                <Grid item xs={12} sm={12}>

                <Box
                    sx={{
                        alignItems: 'flex-end',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        ml: 1,
                        mr: 1,
                        mb: 0
                    }}
                >
                    <Typography
                     sx={{ fontSize: "18px" }}
                    >
                        Detalhes do Recebimento
                    </Typography>
                    <Box sx={{ m: 1 }}>
                        { canDelete && <Button
                            style={{ textTransform: 'none', fontSize: '10px'}}
                            startIcon={(<Delete style={{ fontSize: "10px" }} />)}
                            color="error"
                            variant="outlined"
                            size="small"
                            disabled={running}
                            disableElevation
                            onClick={() => handleOpenDialog()}
                        >
                            Excluir
                        </Button> }
                    </Box>
                </Box>
                    <FormCard title="Venda">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="saleCode"
                                    name="saleCode"
                                    label="Código da Venda"
                                    value={"KAV-" + sale.code || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="client"
                                    name="client"
                                    label="Cliente"
                                    value={sale.importadora && sale.importadora.razaoSocial || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="dataVenda"
                                    name="dataVenda"
                                    label="Data da Venda"
                                    value={dayjs(sale.dataVenda).format('DD/MM/YYYY').toString() || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="produtoCode"
                                    name="produtoCode"
                                    label="Produto Código ERP"
                                    value={sale.produto && sale.produto.codigoERP || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="produtoNome"
                                    name="produtoNome"
                                    label="Produto"
                                    value={sale.produto && sale.produto.nome || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="produtoDescricao"
                                    name="produtoDescricao"
                                    label="Descrição do Produto"
                                    value={sale.produto && sale.produto.descricao || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="totalVenda"
                                    name="totalVenda"
                                    label="Total da Venda"
                                    value={sale.totalDolar && "$ " + formatFloatToReal(sale.totalDolar, 2) || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="totalRecebido"
                                    name="totalRecebido"
                                    label="Total de Recebimentos"
                                    value={sale.recebimentos && "$ " + formatFloatToReal(sumReceivedValues(sale.recebimentos), 2) || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="totalAberto"
                                    name="totalAberto"
                                    label="Total em Aberto"
                                    value={sale.recebimentos && "$ " + formatFloatToReal(sale.totalDolar - sumReceivedValues(sale.recebimentos), 2) || ""}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </FormCard>
                    <FormCard title="ACC">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="codigoContrato"
                                    name="codigoContrato"
                                    label="Código do Contrato"
                                    value={acc.contratoBanco || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="codigoBacen"
                                    name="codigoBacen"
                                    label="Código Bacen"
                                    value={acc.contratoBacen || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="banco"
                                    name="banco"
                                    label="Banco"
                                    value={acc.banco && acc.banco.razaoSocial || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="dataContrato"
                                    name="dataContrato"
                                    label="Data do Contrato"
                                    value={dayjs(acc.dataContrato).format('DD/MM/YYYY').toString() || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="contaDeposito"
                                    name="contaDeposito"
                                    label="Conta Depósito"
                                    value={acc.contaDeposito || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="naturezaOperacao"
                                    name="naturezaOperacao"
                                    label="Natureza da Operação"
                                    value={acc.naturezaOperacao || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="dataCredito"
                                    name="dataCredito"
                                    label="Data de Crédito"
                                    value={dayjs(acc.dataCredito).format('DD/MM/YYYY').toString() || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="dataLiquidacao"
                                    name="dataLiquidacao"
                                    label="Data de Liquidação"
                                    value={dayjs(acc.dataLiquidacaoContrato).format('DD/MM/YYYY').toString() || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="totalDolar"
                                    name="totalDolar"
                                    label="Total em Dólar"
                                    value={acc.valorMonetarioDolar && "$ " + formatFloatToReal(acc.valorMonetarioDolar, 2) || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="taxaCambial"
                                    name="taxaCambial"
                                    label="Total Cambial"
                                    value={acc.taxaCambial && "$ " + formatFloatToReal(acc.taxaCambial, 2) || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="totalReais"
                                    name="totalReais"
                                    label="Total em Reais"
                                    value={acc.valorMonetarioReal && "$ " + formatFloatToReal(acc.valorMonetarioReal, 2) || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="taxaJuros"
                                    name="taxaJuros"
                                    label="Taxa de Juros"
                                    value={acc.taxaJuros && "$ " + formatFloatToReal(acc.taxaJuros, 2) || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="totalRecebimentoAcc"
                                    name="totalRecebimentoAcc"
                                    label="Total Recebimento"
                                    value={acc.recebimentos && "$ " + formatFloatToReal(sumReceivedValues(acc.recebimentos), 2) || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="totalAbertoAcc"
                                    name="totalAbertoAcc"
                                    label="Total em Aberto"
                                    value={acc.recebimentos && "$ " + formatFloatToReal(acc.valorMonetarioDolar - sumReceivedValues(acc.recebimentos), 2) || ""}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </FormCard>
                    <FormCard title="Recebimento">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="valorRecebido"
                                    name="valorRecebido"
                                    label="Valor Recebido"
                                    value={receiving.valorRecebido && "$ " + formatFloatToReal(receiving.valorRecebido, 2) || ""}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size='small'
                                    InputProps={{
                                        readOnly: true,
                                        style: { fontSize: "12px" },
                                        sx: { backgroundColor: '#fafafa' }
                                    }}
                                    InputLabelProps={{ shrink: true, style: { fontSize: "12px" } }}
                                    inputProps={{
                                        maxLength: 60,
                                    }}
                                    id="dataRecebimento"
                                    name="dataRecebimento"
                                    label="Data do Recebimento"
                                    value={dayjs(receiving.creationDate).format('DD/MM/YYYY').toString() || ""}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </FormCard>
                    <FormCard title="Arquivos do Recebimento">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <List sx={{ width: '100%', maxWidth: 400, bgcolor: '#fff', display: 'flex', flexDirection: 'row', padding: 0 }}>
                                    {receiving.arquivos && Object.entries(receiving.arquivos).map(([key, value]) => (
                                        <ListItem key={key}>
                                            <Button style={{ textTransform: 'none' }} variant="outlined" onClick={() => downloadFile(key)}
                                                startIcon={<FileDownloadIcon />}
                                            >
                                                {key}
                                            </Button>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </FormCard>
                </Grid>
            </Paper>
        </Container>
    );
}

export default ReceivingView;
