import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { formatFloatToDolar, formatFloatToReal } from "../../common/utils";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function descendingComparator(a, b, orderBy) {
  let elemA = b[orderBy];
  let elemB = a[orderBy];

  if (elemB < elemA) {
    return -1;
  }
  if (elemB > elemA) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'razaoSocial',
    numeric: false,
    disablePadding: false,
    label: 'Banco',
  },
  // {
  //   id: "contratoBanco",
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Contrato',
  // },
  // {
  //   id: "contratoBacen",
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Bacen',
  // },
  {
    id: 'valorMonetarioDolar',
    numeric: false,
    disablePadding: false,
    label: 'Contratado',
  },
  {
    id: 'totalQuitado',
    numeric: false,
    disablePadding: false,
    label: 'Quitado',
  },
  {
    id: 'totalEmAberto',
    numeric: false,
    disablePadding: false,
    label: 'Pendente',
  },
  {
    id: 'creditoDisponivel',
    numeric: false,
    disablePadding: false,
    label: 'Crédito Disponível',
  },
  // {
  //   id: 'dataContrato',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Data',
  // },
  // {
  //   id: 'dataLiquidacaoContrato',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Vencimento',
  // },
  // {
  //   id: 'renegociado',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Renegociado',
  // },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Percentual',
  },
  // {
  //   id: 'actions',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Ações',
  // }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontSize: '12px', fontWeight: 'bold' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >

    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const AccTable = props => {
  const { rows } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const goToAcc = (id) => {
    navigate(`/accs/${id}`, { id: id });
  }

  const handleClick = (id) => {
    goToAcc(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;



  return (
    <Box sx={{ width: '100%' }}>
      <Card variant="outline" sx={{ borderRadius: '10px', borderColor: "#fff" }}>
        <CardContent>
          <Grid container alignItems="center">
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size='small'
              >
                <EnhancedTableHead
                  // numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          style={{ cursor: 'pointer', height: '60px' }}
                        >
                          <TableCell>
                          </TableCell>
                          <TableCell style={{ width: '20%', fontSize: '11px' }} align="left" padding="normal">{row.razaoSocial}</TableCell>
                          {/* <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{row.contratoBanco}</TableCell>
                        <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{row.contratoBacen}</TableCell> */}
                          <TableCell style={{ width: '20%', fontSize: '11px' }} align="left" padding="normal">{'$ ' + formatFloatToReal(row.totalContratadoDolar)}</TableCell>
                          <TableCell style={{ width: '20%', fontSize: '11px' }} align="left" padding="normal">{'$ ' + formatFloatToReal(row.totalRecebidoDolar)}</TableCell>
                          <TableCell style={{ width: '20%', fontSize: '11px' }} align="left" padding="normal">{'$ ' + formatFloatToReal(row.totalContratadoDolar - row.totalRecebidoDolar)}</TableCell>
                          <TableCell style={{ width: '20%', fontSize: '11px' }} align="left" padding="normal">{'$ ' + formatFloatToReal(row.limiteCredito - row.totalContratadoDolar + row.totalRecebidoDolar)}</TableCell>
                          {/* <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{formatFloatToReal(row.taxaJuros)}% A.A</TableCell>
                        <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{dayjs(row.dataContrato).format('DD/MM/YYYY').toString()}</TableCell>
                        <TableCell style={{ width: '10%', fontSize: '11px' }} align="left" padding="normal">{dayjs(row.dataLiquidacaoContrato).format('DD/MM/YYYY').toString()}</TableCell>
                        <TableCell style={{ width: '5%', fontSize: '11px' }} align="left" padding="normal">{row.historico.length !== 0 ? "Sim" : "Não"}</TableCell> */}
                          <TableCell style={{ width: '20%', fontSize: '11px' }} align="left" padding="normal">
                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                              <CircularProgress variant="determinate" color={((row.limiteCredito - row.totalContratadoDolar + row.totalRecebidoDolar)/row.limiteCredito) * 100 >= 80 ? "success" : "warning"} value={ row.limiteCredito > 0 ? ((row.limiteCredito - row.totalContratadoDolar + row.totalRecebidoDolar)/row.limiteCredito) * 100 : 0} />
                              <Box
                                sx={{
                                  top: 0,
                                  left: 0,
                                  bottom: 0,
                                  right: 0,
                                  position: 'absolute',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              > {
                                row.limiteCredito > 0 &&

                                <Typography variant="caption" component="div" color="text.primary" style={{ fontSize: "12px" }}>
                                  {`${Math.round(((row.limiteCredito - row.totalContratadoDolar + row.totalRecebidoDolar)/row.limiteCredito) * 100)}%`}
                                </Typography>
                                }
                              </Box>
                            </Box>
                          </TableCell>
                          {/* <TableCell style={{ width: '5%' }} align="left" padding="normal">
                        <IconButton
                          onClick={(event) => handleClick(row.id)}
                          size='small'>
                          <VisibilityIcon fontSize='small' />
                        </IconButton>
                      </TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 33 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AccTable;