import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Button, Paper } from '@mui/material';
import { Download, Add } from "@mui/icons-material";


const ButtonsOptions = props => {
    const navigate = useNavigate();

    function goToCreateUser() {
        navigate("/users/create");
    }

    return (
        <Paper variant="outlined" sx={{ width: '100%', mb: 1 }}>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    ml: 2,
                    mr: 2,
                    mb: 0
                }}
                >
                <Typography
                    sx={{ m: 2 }}
                    variant="h5"
                    >
                    Usuários
                </Typography>
                <Box sx={{ m: 1 }}>
                    <Button
                        startIcon={(<Download />)}
                        sx={{ mr: 2 }}
                        size="small"
                        variant="outlined"
                        disableElevation
                        >
                        Exportar
                    </Button>
                    <Button
                        startIcon={(<Add />)}
                        color="primary"
                        variant="contained"
                        size="small"
                        disableElevation
                        onClick={() => goToCreateUser()}
                        >
                        Adicionar
                    </Button>
                </Box>
            </Box>
        </Paper>
    )
};

export default ButtonsOptions;