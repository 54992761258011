import {
  Box,
  Button,
  Container,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@mui/material';
import {
  CelTextMask,
  TelTextMask,
} from '../../components/masks';
import { Delete, Edit } from "@mui/icons-material";
import React, { useEffect, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import MuiAlert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { USER_ROLES } from './roles';
import { api } from '../../services/api';
import { checkRole } from '../../services/auth';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const UserView = (props) => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [userBack, setUserBack] = useState({});
  const navigate = useNavigate();

  const [editing, setEditing] = useState(false)
  const [running, setRunning] = useState(false)
  const [open, setOpen] = useState(false);

  const updateUser = async (item, id) => {
    delete item.authorities
    delete item.id
    delete item.creationDate
    delete item.senha
    delete item.password
    delete item.ativoDesde
    const resp = api.putUser(JSON.stringify(item), id);
    return resp;
  }

  const deleteUser = async id => {
    const resp = api.delUser(id);
    return resp;
  }

  const delay = ms => new Promise(res => {
    setTimeout(res, ms);
    setOpen(true)
  });

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleDelete = async id => {
    setRunning(true);
    if (deleteUser(id)) {
      await delay(2000);
      navigate(-1);
    }
  }

  const handleEdit = async id => {
    setEditing(true);
  }

  const handleSubmit = async e => {
    setRunning(true);
    e.preventDefault();
    if (updateUser(user, id)) {
      await delay(2000);
    }
    setOpen(true);
    setRunning(false);
    setEditing(false);
  }

  const cancelSubmit = () => {
    setUser(userBack);
    setEditing(false);
  }

  useEffect(() => {
    let mounted = true;
    api.getOneUser(id)
      .then(resp => {
        if (mounted) {
          setUser(resp);
          setUserBack(resp);
        }
      })
    return () => mounted = false;
  }, [])
  return (
    <Container
      maxWidth="xg"
      sx={{ mt: 4, mb: 4 }}
      component="form"
      onSubmit={handleSubmit}
      >

      <Grid item xs={12}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              O Usuário foi excluído com sucesso!
            </Alert>
          </Snackbar>
        </Stack>

        <Paper variant="outlined" sx={{ width: '100%', mb: 1 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              ml: 2,
              mr: 2,
              mb: 0
            }}
          >
            <Typography
              sx={{ m: 2 }}
              variant="h5"
            >
              Informações do Usuário
            </Typography>
            { !editing ?
              <Box sx={{ m: 1 }}>
              <Button
                startIcon={(<Edit />)}
                sx={{ mr: 2 }}
                size="small"
                variant="contained"
                disabled={running}
                disableElevation
                onClick={() => handleEdit(id)}
              >
                Editar
              </Button>
              <Button
                startIcon={(<Delete />)}
                color="primary"
                variant="contained"
                size="small"
                disabled={running}
                disableElevation
                onClick={() => handleDelete(id)}
              >
                Excluir
              </Button>
            </Box> : null }
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Fade
          in={running}
          style={{
            transitionDelay: running ? '100ms' : '0ms',
          }}
          unmountOnExit
        >
          <LinearProgress />
        </Fade>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 } }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <TextField

                disabled={running}
                InputProps={{ readOnly: !editing }}
                InputLabelProps={{ shrink: true }}
                id="fullName"
                name="fullName"
                label="Nome"
                value={user.nome}
                onChange={event => setUser({ ...user, nome: event.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField

                disabled={running}
                InputProps={{ readOnly: !editing }}
                InputLabelProps={{ shrink: true }}
                id="email"
                name="email"
                label="Email"
                value={user.email}
                onChange={event => setUser({ ...user, email: event.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                disabled={running}
                InputProps={{ readOnly: !editing }}
                InputLabelProps={{ shrink: true }}
                id="status"
                name="status"
                label={user.inativoDesde ? "Inativo Desde" : "Ativo Desde"}
                value={user.inativoDesde ? dayjs(user.inativoDesde).format('DD/MM/YYYY').toString() : dayjs(user.ativoDesde).format('DD/MM/YYYY').toString()}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                disabled={running}
                InputProps={{
                  readOnly: !editing,
                  inputComponent: TelTextMask
                }}
                InputLabelProps={{ shrink: true }}
                id="telefone"
                name="telefone"
                label="Telefone"
                value={user.telefone}
                onChange={event => setUser({ ...user, telefone: event.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                disabled={running}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: !editing,
                  inputComponent: CelTextMask
                }}
                id="celular"
                name="celular"
                label="Celular"
                value={user.celular}
                onChange={event => setUser({ ...user, celular: event.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                disabled={running}
                InputLabelProps={{ shrink: true }}
                InputProps={{readOnly: !editing}}
                id="setor"
                name="setor"
                label="Setor"
                value={user.setor}
                onChange={event => setUser({ ...user, setor: event.target.value })}
                fullWidth
              />
            </Grid>
            {editing ?
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel id="input-unit">Papel</InputLabel>
                  <Select
                    disabled={running}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{readOnly: !editing}}
                    required
                    labelId="role"
                    id="role"
                    label="Papel"
                    value={user.role}
                    onChange={event => setUser({ ...user, role: event.target.value, 
                      authorities: [{"authority": `ROLE_${event.target.value}`}]})}
                  >
                    {Object.keys(USER_ROLES).map((key, index) => (
                    <MenuItem key={index} value={key}>{USER_ROLES[key]}</MenuItem>
                  ))}
                  </Select>
                </FormControl>
              </Grid> :
              <Grid item xs={12} sm={3}>
                <TextField
                  disabled={running}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{readOnly: !editing}}
                  id="role"
                  name="role"
                  label="Papel"
                  value={USER_ROLES[user.role]}
                  fullWidth
                />
              </Grid>
            }
            {editing && checkRole("ADMIN") ?
              <Grid item >
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} sm={6}>
                    <LoadingButton
                      loading={running}
                      type="submit"
                      startIcon={(<SaveIcon />)}
                      color="primary"
                      variant="contained"
                      size="medium"
                      disableElevation
                      sx={{ width: "200px" }}
                    >
                      Salvar
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LoadingButton
                      loading={running}
                      startIcon={(<CancelIcon />)}
                      color="error"
                      variant="contained"
                      size="medium"
                      disableElevation
                      onClick={() => cancelSubmit()}
                      sx={{ width: "200px" }}
                    >
                      Cancelar
                    </LoadingButton>
                  </Grid>
                </Grid>

              </Grid>
              : null}
          </Grid>
        </Paper>
      </Grid>
    </Container>
  );
}

export default UserView;