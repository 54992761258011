import {
  Box,
  Container,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@mui/material';
import {
  CelTextMask,
  TelTextMask,
} from '../../components/masks';
import React, { useState } from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import MuiAlert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { USER_ROLES } from './roles';
import { api } from '../../services/api';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

const createNewUser = async item => {
  const resp = api.postUser(JSON.stringify(item));
  return resp;
}

const userBase = {
  "nome": "",
  "email": "",
  "telefone": "",
  "celular": "",
  "setor": "",
  "role": "",
  "senha": ""
}

export default function UsersCreate() {

  const [running, setRunning] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [inputFields, setInputFields] = useState(userBase);

  const handleSubmit = async e => {
    setRunning(true);
    e.preventDefault();
    const resp = await createNewUser(inputFields);
    if (resp) {
      setMessage("Usuário cadastrado com sucesso!");
      setSeverity("success");
    } else {
      setMessage("Erro ao cadastrar novo usuário!");
      setSeverity("error");
    }
    setInputFields(userBase);
    setOpen(true);
  }

  const handleClose = () => {
    setRunning(false);
    setOpen(false);
  };

  return (
    <Container
      component="form"
      maxWidth="xg"
      sx={{ mt: 4, mb: 4 }}
      onSubmit={handleSubmit}>
      <Grid item xs={12} sx={{ mb: 1 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/users">
            Usuários
          </Link>
          <Link underline="hover" color="text.primary" href="/users/create"
          >
            Novo
          </Link>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>
        </Stack>

        <Paper variant="outlined" sx={{ width: '100%', mb: 1 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              ml: 2,
              mr: 2,
              mb: 0
            }}
          >
            <Typography
              sx={{ m: 2 }}
              variant="h5"
            >
              Novo Usuário
            </Typography>
          </Box>
        </Paper>
        <Fade
          in={running}
          style={{
            transitionDelay: running ? '100ms' : '0ms',
          }}
          unmountOnExit
        >
          <LinearProgress />
        </Fade>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 } }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <TextField
                disabled={running}
                required
                id="name"
                name="name"
                label="Nome"
                value={inputFields.nome}
                onChange={event => setInputFields({ ...inputFields, nome: event.target.value })}
                fullWidth
              // InputLabelProps={{ shrink: inputFields.fullName }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                disabled={running}
                required
                id="email"
                name="email"
                label="Email"
                value={inputFields.email}
                onChange={event => setInputFields({ ...inputFields, email: event.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                disabled={running}
                required
                id="senha"
                name="senha"
                label="Senha"
                value={inputFields.senha}
                onChange={event => setInputFields({ ...inputFields, senha: event.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                disabled={running}
                required
                id="telefone"
                name="telefone"
                label="Telefone"
                value={inputFields.telefone}
                onChange={event => setInputFields({ ...inputFields, telefone: event.target.value })}
                InputProps={{
                  inputComponent: TelTextMask
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                disabled={running}
                required
                id="celular"
                name="celular"
                label="Celular"
                value={inputFields.celular}
                onChange={event => setInputFields({ ...inputFields, celular: event.target.value })}
                InputProps={{
                  inputComponent: CelTextMask
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel id="input-setor">Setor</InputLabel>
                <Select
                  disabled={running}
                  required
                  labelId="setor"
                  id="setor"
                  name="setor"
                  label="Setor"
                  value={inputFields.setor}
                  onChange={event => setInputFields({ ...inputFields, setor: event.target.value })}
                >
                  <MenuItem value={"Administrativo"}>Administrativo</MenuItem>
                  <MenuItem value={"Contábil"}>Contábil</MenuItem>
                  <MenuItem value={"Tecnologia"}>Tecnologia</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <InputLabel id="input-role">Papel</InputLabel>
                <Select
                  disabled={running}
                  required
                  labelId="role"
                  id="role"
                  name="role"
                  label="Papel"
                  value={inputFields.role}
                  onChange={event => setInputFields({ ...inputFields, role: event.target.value })}
                >
                  {Object.keys(USER_ROLES).map((key, index) => (
                    <MenuItem key={index} value={key}>{USER_ROLES[key]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container>
                <LoadingButton
                  loading={running}
                  type="submit"
                  startIcon={(<SaveIcon />)}
                  // loadingPosition="start"
                  color="primary"
                  variant="contained"
                  size="medium"
                  disableElevation
                  sx={{ width: "200px" }}
                // onClick={() => createNewProduct(inputFields)}
                >
                  Salvar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Container>
  );
}